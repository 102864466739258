import React, { useContext, useRef, useState } from "react";
import { RiEdit2Line } from "react-icons/ri";
import { Drawer } from "@mui/material";
import { VscClose } from "react-icons/vsc";
import { BsCheck2 } from "react-icons/bs";
import sideImg from "../../assets/images/sidebar-left.png";
import ProfileInfo from "./ProfileInfo";
import AddressInfo from "./AddressInfo";
import { QRCodeSVG } from "qrcode.react";
import { FormContext } from "../../components/FormContext";
import { useToast } from "../../ToastContext";
import { CiEdit } from "react-icons/ci";
import { CreateAndUpdateUser, UserDetails } from "../../api/userProfile";
import { CircularProgress } from "@mui/material";
import { HiUserCircle } from "react-icons/hi2";
import Tooltip from '@mui/material/Tooltip';

function Profile() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState("");
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
  const fileInputRef = useRef(null);
  const toast = useToast();
  const { formData, setFormData, profileData, setProfileData } =
    useContext(FormContext);
  const userId = Number(localStorage.getItem("userId"));
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const response = await UserDetails(userId);
      if (response && response?.data) {
        const {
          FirstName,
          LastName,
          EmailId,
          PhoneNumber,
          Address1,
          Address2,
          City,
          State,
          Country,
          IsUserProfile,
          UserProfilePhoto,
        } = response.data;

        setFormData((prev) => ({
          ...prev,
          firstName: FirstName || "",
          lastName: LastName || "",
          email: EmailId || "",
          phoneNumber: PhoneNumber || "",
          IsUserProfile: IsUserProfile || null,
          address1: Address1 || "",
          address2: Address2 || "",
          city: City || "",
          state: State || "",
          country: Country || "",
          UserProfilePhoto: UserProfilePhoto || "",
        }));
      }
    } catch (error) {
      toast.error("Failed to fetch user details. Please try again.");
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleEdit = (section) => {
    setDrawerContent(section);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleInputChange = (field, value) => {
    setProfileData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("isCreate", profileData.IsUserProfile ? false : true);
    formData.append("firstName", profileData.firstName);
    formData.append("lastName", profileData.lastName);
    formData.append("userId", userId);
    formData.append("phoneNumber", profileData.phoneNumber);
    formData.append("address1", profileData.address1 || "");
    formData.append("address2", profileData.address2 || "");
    formData.append("country", profileData.country);
    formData.append("city", profileData.city);
    formData.append("state", profileData.state);
    formData.append("profilePhoto", profileData.UserProfilePhoto || null);

    try {
      const response = await CreateAndUpdateUser(formData);
      if (response && response.data) {
        toast.success("Profile updated successfully");
        setIsLoading(false);
        handleClose();
        fetchUserDetails();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error(
        error.response?.data?.message ||
        "Failed to update profile. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxFileSize = 50 * 1024 * 1024;
      if (file.size > maxFileSize) {
        toast.error("File size exceeds 50MB. Please choose a smaller file.");
        return;
      }
      setUploadedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    const formDatas = new FormData();
    formDatas.append("isCreate", formData.IsUserProfile ? false : true);
    formDatas.append("firstName", formData.firstName);
    formDatas.append("lastName", formData.lastName);
    formDatas.append("userId", userId);
    formDatas.append("phoneNumber", formData.phoneNumber);
    formDatas.append("address1", formData.address1 || "");
    formDatas.append("address2", formData.address2 || "");
    formDatas.append("country", formData.country);
    formDatas.append("city", formData.city);
    formDatas.append("state", formData.state);
    formDatas.append("profilePhoto", uploadedImage);
    try {
      const response = await CreateAndUpdateUser(formDatas);
      if (response && response?.data) {
        fetchUserDetails();
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(
        error.response?.data?.message ||
        "Failed to upload image. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderDrawerContent = () => {
    switch (drawerContent) {
      case "profileInfo":
        return (
          <ProfileInfo
            setProfileData={setProfileData}
            profileData={profileData}
            formData={formData}
            handleInputChange={handleInputChange}
            sideImg={sideImg}
            handleClose={handleClose}
          />
        );
      case "addressInfo":
        return (
          <AddressInfo
            setProfileData={setProfileData}
            profileData={profileData}
            formData={formData}
            handleInputChange={handleInputChange}
            sideImg={sideImg}
            handleClose={handleClose}
          />
        );
      // case "paymentInfo":
      //   return (
      //     <PaymentInfo
      //       setProfileData={setProfileData}
      //       profileData={profileData}
      //       handleInputChange={handleInputChange}
      //       sideImg={sideImg}
      //       formData={formData}
      //       handleClose={handleClose}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-screen flex items-center flex-col bg-white">
      <div className="text-left w-[80%] px-4 py-5 sm:px-8 md:px-16 mt-10 border border-gray-200 rounded-lg">
        <div className="flex flex-row sm:flex-row justify-between items-start mb-8">
          <div className="flex items-center space-x-4 mb-1 sm:mb-0 w-full">
            {formData.UserProfilePhoto !== "" ? (
              <>
                <img
                  src={formData.UserProfilePhoto}
                  alt="Avatar"
                  className="w-24 h-24 rounded-full object-cover border-4 border-blue-400 cursor-pointer"
                  onClick={handleAvatarClick}
                />
                <CiEdit
                  size={30}
                  color="#008080"
                  className="relative top-8 right-11 bg-white rounded-full border-2 border-[#008080] p-1 cursor-pointer"
                  onClick={handleAvatarClick}
                />
              </>
            ) : (
              <div onClick={handleAvatarClick} className="cursor-pointer">
                <HiUserCircle size={90} color="#808080" />
                <CiEdit
                  size={30}
                  color="#008080"
                  className="relative bottom-9 left-14 bg-white rounded-full border-2 border-[#008080] p-1 cursor-pointer"
                />
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
            />
          </div>
          <div>
            <QRCodeSVG
              value={websiteUrl}
              size={80}
              fgColor="#000000"
              bgColor="#FFFFFF"
            />
          </div>
        </div>
        <SectionHeader
          title="Profile Information"
          isEditable={true} // or false based on your logic
          onEdit={() => handleEdit("profileInfo")}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
          <InputField label="First Name" value={formData.firstName} />
          <InputField label="Last Name" value={formData.lastName} />
          <InputField label="Email" value={formData.email} />
          <InputField label="Phone Number" value={formData.phoneNumber} />
        </div>
        <SectionHeader
          isEditable={true} // or false based on your logic
          title="Address Information"
          onEdit={() => handleEdit("addressInfo")}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
          <InputField label="Address" value={formData.address1} />
          <InputField label="City" value={formData.city} />
          <InputField label="State" value={formData.state} />
          <InputField label="Country" value={formData.country} />
        </div>
        {/* <SectionHeader
          title="Payment Information"
          onEdit={() => handleEdit("paymentInfo")}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <InputField
            label="Card Holder Name"
            value={formData.cardHolderName}
          />
          <InputField label="Card Number" value={formData.cardNumber} />
        </div> */}
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: {
              xs: "100%",
              sm: "500px",
              md: "700px",
            },
          },
        }}
      >
        <div className="w-full">
          <div>{renderDrawerContent()}</div>
          <div className="flex justify-end items-end gap-8 bg-white p-4 fixed bottom-0 right-0 w-full sm:w-auto sm:flex-row sm:gap-4">
            <button
              onClick={handleClose}
              className="font-normal cursor-pointer text-sm px-4 py-3 border-gray-300 border-2 text-gray-700 rounded hover:bg-gray-300 flex items-center w-full sm:w-28 sm:h-12 justify-center"
            >
              <VscClose size={16} className="mr-3" />
              Cancel
            </button>

            <button
              onClick={handleSubmit}
              className={`font-normal cursor-pointer text-sm ${isLoading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-customGreen hover:bg-[#006666]"
                } text-white rounded transition duration-200 flex items-center justify-center w-full sm:w-28 sm:h-12 py-3`}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex justify-center items-center w-full">
                  <CircularProgress size={14} color="inherit" />
                </div>
              ) : (
                <>
                  <BsCheck2 size={16} className="mr-3" />
                  Submit
                </>
              )}
            </button>
          </div>
        </div>
      </Drawer>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-80">
            <h2 className="text-lg font-semibold text-gray-800">
              Confirm Upload
            </h2>
            {previewImage && (
              <img
                src={previewImage}
                alt="Uploaded"
                className="mt-4 w-32 h-32 rounded-full mx-auto border shadow"
              />
            )}
            <div className="mt-6 flex justify-end space-x-3">
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={handleCancel}
                  className="w-32 px-4 cursor-pointer py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 text-center"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirm}
                  disabled={isLoading}
                  className="w-32 px-4 py-2 cursor-pointer bg-blue-500 text-white rounded hover:bg-blue-600 text-center"
                >
                  {isLoading ? (
                    <span className="flex justify-center items-center">
                      <CircularProgress size={16} color="inherit" />
                    </span>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const SectionHeader = ({ title, onEdit, isEditable }) => (
  <div className="flex items-center gap-3">
    <h2 className="font-semibold text-base mb-2 whitespace-nowrap font-SegoeUI text-[#696A6C]">
      {title}
    </h2>
    {isEditable ? (
      <Tooltip title="Click to Edit" arrow placement="top">
        <button onClick={onEdit} className="cursor-pointer">
          <RiEdit2Line size={18} color="grey" alt="Click to Edit" />
        </button>
      </Tooltip>
    ) : (
      <Tooltip title="Please sign in to continue" arrow placement="bottom">
        <span className="cursor-not-allowed">
          <RiEdit2Line size={18} color="grey" />
        </span>
      </Tooltip>
    )}
    <hr className="flex-grow border-t border-gray-300" />
  </div>
);

const InputField = ({ label, value }) => (
  <div>
    <label className="block text-sm font-medium text-gray-600">{label}</label>
    <input
      type="text"
      value={value}
      readOnly
      className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md bg-[#FAFAFA] outline-none"
    />
  </div>
);

export default Profile;
