import React, { useContext } from "react";
import Modal from "react-modal";
import { UserFormContext } from "../../components/UserFormContext";
import { CircularProgress } from "@mui/material";

Modal.setAppElement("#root");

const SaveDraft = ({ isOpen, onClose, onSaveDraft }) => {
	const { loading } = useContext(UserFormContext);
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel="Save Draft Modal"
			overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10"
			className="bg-white rounded-lg shadow-lg p-6 w-96 text-center"
		>
			<h2 className="text-xl font-bold mb-4">Save Draft</h2>
			<p className="mb-6">Do you want to save the draft or cancel?</p>
			<div className="flex justify-around flex-col lg:flex-row items-center w-full gap-5">
				<div className="flex flex-col sm:flex-row justify-center gap-4 w-full">
					<button
						onClick={onClose}
						className="w-full sm:w-[40%] px-6 py-2 bg-gray-300 rounded hover:bg-gray-400 min-h-[40px] flex items-center justify-center"
					>
						Cancel
					</button>
					<button
						onClick={onSaveDraft}
						className="w-full sm:w-[40%] px-6 py-2 bg-customGreen text-white rounded flex items-center justify-center hover:bg-teal-700 min-h-[40px]"
						disabled={loading}
					>
						{loading ? (
							<CircularProgress size={20} color="inherit" />
						) : (
							"Save Draft"
						)}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default SaveDraft;
