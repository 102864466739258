import React from "react";
import ReactModal from "react-modal";
import { PiBookmarksLight } from "react-icons/pi";

function ConfirmModal({ show, status, onConfirm, onClose }) {
  return (
    <>
      {show && (
        <ReactModal
          isOpen={show}
          onRequestClose={onClose}
          className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto my-20 relative w-full sm:w-[30%] md:w-[55%] lg:w-[45%] "
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[2000]"
          ariaHideApp={false}
        >
          <div className="flex flex-col items-center justify-center text-center ">
            <PiBookmarksLight
              className={
                status === "approved"
                  ? "text-customGreen"
                  : status === "reviewed"
                    ? "text-customGreen"
                    : "text-red-500"
              }
              size={50}
            />
            <span className="mt-4 font-semibold text-lg text-black font-Poppins">
              Please confirm if you would like to{" "}
              {status === "approved"
                ? "approve"
                : status === "reviewed"
                  ? "review"
                  : "reject"}{" "}
              this order!
            </span>
            <div className="flex mt-6 w-full justify-center sm:justify-around flex-col sm:flex-row gap-4">
              <button
                onClick={onClose}
                className="font-roboto cursor-pointer font-normal w-full sm:w-auto px-20 py-2 text-[#2C363F] border border-gray-300 rounded hover:bg-gray-200 bg-[#FCFCFD]"
              >
                No
              </button>
              <button
                onClick={onConfirm}
                className={`px-20 py-2 cursor-pointer rounded ${status === "approved"
                  ? "bg-customGreen text-white hover:bg-[#006666]"
                  : status === "reviewed"
                    ? "bg-customGreen text-white hover:bg-[#006666]"
                    : "bg-red-500 text-white hover:bg-red-600"
                  }`}
              >
                Yes
              </button>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
}

export default ConfirmModal;
