import React from "react";

const InputField = ({ label, value }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div
        className="mt-1 block w-full h-auto rounded-md pl-2 pt-4 pb-4 bg-[#FAFAFA] shadow-sm sm:text-sm"
        style={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          overflowWrap: "break-word",
        }}
      >
        {value}
      </div>
    </div>
  );
};

export default InputField;
