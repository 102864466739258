import React from "react";
import { GoCheckCircle } from "react-icons/go";
import ReactModal from "react-modal";

function ConfirmationMessage({ show, onClose, status }) {
  return (
    <div>
      {show && (
        <ReactModal
          isOpen={show}
          onRequestClose={onClose}
          className="w-full sm:w-11/12 md:w-3/5 lg:w-2/6 xl:w-[25%] max-w-lg h-[27%] bg-white rounded-lg shadow-lg p-6 flex items-center justify-center"
          overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[2000]"
        >
          <div className="w-full text-center flex flex-col items-center">
            <GoCheckCircle
              className={
                status === "approved" || status === "reviewed"
                  ? "text-customGreen"
                  : "text-red-500"
              }
              size={50}
            />
            {status === "approved" && (
              <span className="mt-4 text-lg font-semibold font-Poppins text-customGreen">
                Approved Successfully!
              </span>
            )}
            {status === "reviewed" && (
              <span className="mt-4 text-lg font-semibold font-Poppins text-customGreen">
                Reviewer Successfully!
              </span>
            )}
            {status === "rejected" && (
              <span className="mt-4 text-lg font-semibold font-Poppins text-red-500">
                Order Rejected Successfully!
              </span>
            )}
          </div>
        </ReactModal>
      )}
    </div>
  );
}

export default ConfirmationMessage;
