import axios from "axios";

const url = process.env.REACT_APP_API_URL + "user/signup";
const URL = process.env.REACT_APP_API_URL + "user";

export const registerAPI = async (usefData) => {
  const response = await axios.post(url, usefData);
  return response;
};

export const AuthSignUpAPI = async (payload) => {
  const response = await axios.post(`${URL}/auth-signup`, payload);
  return response;
};

export const AuthSignUpCognito = async (payload) => {
  const response = await axios.post(`${URL}/cognito-verification`, payload);
  return response;
};
