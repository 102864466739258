import React, { createContext, useContext, useState } from 'react';
import { UserFormContext } from './UserFormContext';

const NavigationContext = createContext();
export const useNavigation = () => useContext(NavigationContext);
export const NavigationProvider = ({ children }) => {
    const {
        setFormData,
        setPhotochildFiles,
        setActivityCompanionFiles,
        setChildHospitalPhotoFiles,
        setChallengingActivityFiles,
        setchildFriendsFamilyFiles,
        setChildMentorHelpingFiles,
        setChildMentorHelpingSucceedFiles,
        setChildFacedChallengeFiles,
        setChildvictoriousHappyFiles,
        setChildSupportiveFamilyFiles,
        setChildSupportiveFamilyTwoFiles,
        setChildphotoMembersTeamFiles,
        setChildOneCloseEyesFiles,
    } = useContext(UserFormContext);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [draftModalIsOpen, setDraftModalIsOpen] = useState(false);
    const [targetPage, setTargetPage] = useState("");
    const userData = JSON.parse(localStorage.getItem("userData")) || {}; // Parse stored data
    let BookId = userData?.BookDetails?.Id;

    const handleNavigation = (page) => {
        if (isFormDirty) {
            setDraftModalIsOpen(true);
            setTargetPage(page);
        }
        else {
            if (userData?.BookDetails) {
                BookId = "";
            }
            localStorage.removeItem("userData");
            window.location.href = page;
            setIsFormDirty(false)
            setDraftModalIsOpen(false);
            setPhotochildFiles([])
            setActivityCompanionFiles([]);
            setChildHospitalPhotoFiles([]);
            setChallengingActivityFiles([]);
            setchildFriendsFamilyFiles([]);
            setChildMentorHelpingFiles([]);
            setChildMentorHelpingSucceedFiles([]);
            setChildFacedChallengeFiles([]);
            setChildvictoriousHappyFiles([]);
            setChildSupportiveFamilyFiles([]);
            setChildSupportiveFamilyTwoFiles([]);
            setChildphotoMembersTeamFiles([]);
            setChildOneCloseEyesFiles([]);
            setFormData({});
        }
    };

    const handleCancel = () => {
        if (userData?.BookDetails) {
            BookId = "";
        }
        localStorage.removeItem("userData");
        setIsFormDirty(false)
        setDraftModalIsOpen(false);
        setPhotochildFiles([])
        setActivityCompanionFiles([]);
        setChildHospitalPhotoFiles([]);
        setChallengingActivityFiles([]);
        setchildFriendsFamilyFiles([]);
        setChildMentorHelpingFiles([]);
        setChildMentorHelpingSucceedFiles([]);
        setChildFacedChallengeFiles([]);
        setChildvictoriousHappyFiles([]);
        setChildSupportiveFamilyFiles([]);
        setChildSupportiveFamilyTwoFiles([]);
        setChildphotoMembersTeamFiles([]);
        setChildOneCloseEyesFiles([]);
        setFormData({});
    };

    return (
        <NavigationContext.Provider value={{
            isFormDirty,
            setIsFormDirty,
            draftModalIsOpen,
            setDraftModalIsOpen,
            handleNavigation,
            targetPage,
            handleCancel,
        }}>
            {children}
        </NavigationContext.Provider>
    );
};
