import React from 'react';
import ReactModal from 'react-modal';
import { ScrollArea } from '../../../components/scroll-area';
import { IoIosCloseCircleOutline } from "react-icons/io";

const TermsAndConditionsModal = ({ isOpen, onClose, setTermsAccepted, termsAccepted }) => {

	const handleCheckboxChange = (e) => {
		setTermsAccepted(e.target.checked);
	};

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="bg-white rounded-lg shadow-lg p-6 max-w-2xl my-20 relative w-full sm:w-[50%] md:w-[65%] lg:w-[70%] h-[85%] overflow-hidden"
			overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
		>
			<div className="w-full h-full flex flex-col">
				{/* Header */}
				<div className="w-full flex items-start justify-between pb-4 border-b border-gray-200">
					<span className="text-xl font-bold">Terms & Conditions</span>
					<IoIosCloseCircleOutline color="grey" size={24} className="cursor-pointer" onClick={onClose} />
				</div>

				{/* Scrollable Content */}
				<ScrollArea className="flex-1 mt-4 pr-4">
					<div className="space-y-3 text-sm">
						<p className="font-poppins font-medium text-sm text-grey-base">Effective Date: January 1, 2025</p>
						<h2 className="font-poppins font-medium text-base text-octonary-base">1. Privacy Commitment</h2>
						<p className="font-poppins font-medium text-sm text-grey-base">At Journey Books™, we value your privacy and are committed to protecting your personal information. We will <strong>never sell, trade, or share</strong> your information with third parties for marketing purposes. Your data is used solely to create your personalized Journey Book™ and enhance your experience with our services.</p>
						{/*  */}
						<h2 className="font-poppins font-medium text-base text-octonary-base">2. Information We Collect</h2>
						<p className="font-poppins font-medium text-sm text-grey-base">When you sign up or submit information for your Journey Book™, we may collect:</p>
						<ul className="list-disc ml-5">
							<li className="font-poppins font-medium text-sm text-grey-base">Name, email, and contact details</li>
							<li className="font-poppins font-medium text-sm text-grey-base">Photos and responses for your personalized book</li>
							<li className="font-poppins font-medium text-sm text-grey-base">Any documents or links you upload for your personal advocacy portal</li>
						</ul>
						<p className="font-poppins font-medium text-sm text-grey-base">This information is securely stored and used only for the purpose of customizing and delivering your Journey Book™.</p>
						{/*  */}
						<h2 className="font-poppins font-medium text-base text-octonary-base">3. How We Use Your Information</h2>
						<p className="font-poppins font-medium text-sm text-grey-base">Your information is used to:</p>
						<ul className="list-disc ml-5">
							<li className="font-poppins font-medium text-sm text-grey-base">Create and personalize your Journey Book™</li>
							<li className="font-poppins font-medium text-sm text-grey-base">Improve our services based on user feedback</li>
							<li className="font-poppins font-medium text-sm text-grey-base">Communicate with you about your order, updates, or related resources</li>
						</ul>
						<p className="font-poppins font-medium text-sm text-grey-base">We may also share anonymized insights to improve the Journey Book™ experience, but <strong>no personally identifiable information will ever be disclosed without your consent.</strong></p>
						{/*  */}
						<h2 className="font-poppins font-medium text-base text-octonary-base">4. Data Security</h2>
						<p className="font-poppins font-medium text-sm text-grey-base">We take reasonable steps to protect your information from unauthorized access, loss, or misuse. However, by using our services, you acknowledge that no system is 100% secure, and you share information at your discretion.</p>
						{/*  */}
						<h2 className="font-poppins font-medium text-base text-octonary-base">5. User Rights & Consent</h2>
						<p className="font-poppins font-medium text-sm text-grey-base">By submitting your information, you consent to our use of it for the creation of your Journey Book™. You may request to:</p>
						<ul className="list-disc ml-5">
							<li className="font-poppins font-medium text-sm text-grey-base">View or update your submitted information</li>
							<li className="font-poppins font-medium text-sm text-grey-base">Delete your data from our system after your book has been created</li>
						</ul>
						<p className="font-poppins font-medium text-sm text-grey-base">For any privacy concerns, please contact us at <strong>tom@journeybookstories.com</strong></p>
						{/*  */}
						<h2 className="font-poppins font-medium text-base text-octonary-base">6. Terms of Use</h2>
						<p className="font-poppins font-medium text-sm text-grey-base">By using our website and services, you agree to:</p>
						<ul className="list-disc ml-5">
							<li className="font-poppins font-medium text-sm text-grey-base">Provide accurate information for your Journey Book™</li>
							<li className="font-poppins font-medium text-sm text-grey-base">Not share or distribute Journey Book™ materials for commercial purposes without permission</li>
							<li className="font-poppins font-medium text-sm text-grey-base">Use the Journey Book™ responsibly as an advocacy tool</li>
						</ul>
						<p className="font-poppins font-medium text-sm text-grey-base">We reserve the right to update these terms as needed, and any changes will be reflected on this page.</p>
						{/*  */}
						<div className='w-full flex items-center justify-center text-center pt-5'>
							<h2 className="font-poppins font-medium text-base text-octonary-base">Thank you for trusting us with<br /> your story! </h2>
						</div>
					</div>
				</ScrollArea>

				{/* Checkbox and Button */}
				<div className="flex items-center mt-4 justify-center">
					<input
						type="checkbox"
						className="mr-2 w-4 h-4"
						checked={termsAccepted}
						onChange={handleCheckboxChange}
					/>
					<span className="text-gray-600 text-xs md:text-sm">
						I agree with the <a href="#" className="text-teal-600 hover:underline">Terms & Conditions</a> of Clarity
					</span>
				</div>
				<button
					onClick={onClose}
					disabled={!termsAccepted}
					className={`w-full cursor-pointer mt-4 font-semibold font-Poppins text-xs md:text-sm ${termsAccepted ? "bg-teal-600" : "bg-gray-400"
						} text-white py-2 rounded-md hover:${termsAccepted ? "bg-teal-700" : "bg-gray-400"
						} transition duration-300`}
				>
					Accept
				</button>
			</div>
		</ReactModal>
	);
};

export default TermsAndConditionsModal;
