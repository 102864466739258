import React from "react";
import ReactModal from "react-modal";
import { BiLogOut } from "react-icons/bi";
import { IoIosCloseCircleOutline } from "react-icons/io";

const LogoutModal = ({ isOpen, onClose, onLogout }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto my-20 relative w-full sm:w-[30%] md:w-[55%] lg:w-[45%] "
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10"
    >
      <div className="w-full flex items-center justify-center flex-col">
        <div className="w-full flex items-end justify-end pb-4 cursor-pointer">
          <IoIosCloseCircleOutline color="grey" size={20} onClick={onClose} />
        </div>
        <div>
          <BiLogOut className="text-[#FE1D1D] mb-3" size={45} />
        </div>
        <div>
          <p className="text-base font-poppins mb-3 text-center text-[#2C363F] font-medium">
            Please confirm if you would like
            <br /> to Logout your account!
          </p>
        </div>
        <div className="flex justify-around items-center gap-4 sm:flex-row flex-col pt-3 w-full">
          <button
            onClick={onClose}
            className="bg-gray-100 border border-[#C9D3DB] text-gray-800 px-20 py-2 rounded hover:bg-gray-200 cursor-pointer"
          >
            No
          </button>
          <button
            onClick={onLogout}
            className="bg-[#FE1D1D] text-white px-20 py-2 rounded hover:bg-red-700 cursor-pointer"
          >
            Yes
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default LogoutModal;
