import React, { useContext, useEffect, useRef, useState } from "react";
import "../../style/home.css";
import dashboardImg2 from "../../assets/images/dashboardImg2.png";
import dashboardImg1 from "../../assets/images/dashboardImg1.png";
import circleLine from "../../assets/images/circleLIne.png";
import ChildImgOne from "../../assets/images/home-childImgOne.png";
import ChildImgTwo from "../../assets/images/home-childImgTwo.png";
import circleLineOne from "../../assets/images/circleOne.png";
import circleLineTwo from "../../assets/images/circleTwo.png";
import headImg from "../../assets/images/text-head-img.png";
import AboutUs from "./AboutUs";
import Works from "./Works";
import Book from "./Book";
import FAQ from "./FAQ";
import Story from "./Story";
import Testimonial from "./Testimonial";
import Footer from "../../components/nav-bar/Footer";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import Video from "./Video";
import Tooltip from "@mui/material/Tooltip";
import Advocacy from "./Advocacy";
import { FormContext } from "../../components/FormContext";
import { useToast } from "../../ToastContext";

function Home() {
  const dashboardRef = useRef(null);
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { formData } = useContext(FormContext);
  const toast = useToast();
  const roleId = localStorage.getItem("RoleId");
  const parsedRoleId = parseInt(roleId, 10);

  useEffect(() => {
    if (dashboardRef.current) {
      dashboardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleClick = () => {
    // if (
    //   !formData.address1 ||
    //   !formData.city ||
    //   !formData.state ||
    //   !formData.country
    // ) {
    //   toast.error("Please Update Your Profile to Continue");
    //   return;
    // }

    ReactGA.event({
      category: "Form",
      action: "Navigate to form page",
    });
    navigate("/form");
  };

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.subscribeToStateChange(handleStateChange);
    }
  }, []);

  const handleStateChange = (state) => {
    if (state.paused) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setIsButtonDisabled(!token);
  }, []);

  return (
    <div className="dashboard-page" ref={dashboardRef}>
      <div className="home-container-page">
        <div className="home-first-pages">
          <div className="home-img-content-box">
            <div className="imge-circle-view">
              <img
                src={dashboardImg2}
                className="img-childern-one-homePage"
                alt="dashboardImg2"
              />
            </div>
            <div className="content-btn-container">
              <div className="home-heading-line-container">
                <span className="home-heading-para">
                  “Struggling and exhausted from explaining your child’s needs?”
                </span>
                <hr className="home-center-line" />
                <span className="home-heading-para-ans">
                  A 3-minute Journey Book shares their story, helping others
                  truly understand and support their unique journey.
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    gap: 5,
                    paddingRight: "30px",
                  }}
                >
                  <img
                    src={headImg}
                    alt="img"
                    width={"25.37px"}
                    height={"31.14px"}
                    className="img-heading-home"
                  />
                  <span className="home-page-text">Craft Your Own</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <span className="second-heading-text-home">
                    Journey Books
                  </span>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <p className="home-page-para-text">
                  Personalized stories celebrating every child's unique path
                </p>
              </div>
              <div
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                  padding: "20px",
                  marginTop: "30px",
                }}
              >
                <Tooltip
                  title={isButtonDisabled ? "Please sign in to continue" : ""}
                  arrow
                  placement="bottom"
                >
                  <span>
                    <button
                      className={
                        isButtonDisabled
                          ? "custom-button-disabled"
                          : "custom-button"
                      }
                      disabled={isButtonDisabled}
                      onClick={handleClick}
                    >
                      Get Started Today
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="home-imgs-content-row">
            <div className="circle-line-img-view">
              <img
                src={circleLine}
                className="img-home-page-circle"
                alt="dashboardImg2"
              />
            </div>
            <div className="imge-circle-view-row">
              <img
                src={dashboardImg1}
                className="img-children-homePage-two"
                alt="dashboardImg2"
              />
            </div>
          </div>
        </div>
        <div className="home-first-pages-mobile">
          <div className="home-mobile-first-container">
            <div className="home-mobile-first-img--align">
              <img
                src={circleLineTwo}
                alt="circleTwo"
                width={"37.92px"}
                height={"60px"}
              />
              <img
                src={circleLineOne}
                alt="circleLineOne"
                width={"40.91px"}
                height={"78.27px"}
              />
            </div>
            <div className="content-btn-container">
              <div className="home-heading-line-container">
                <span className="home-heading-para">
                  “Struggling and exhausted from explaining your child’s needs?”
                </span>
                <hr className="home-center-line" />
                <span className="home-heading-para-ans">
                  A 3-minute Journey Book shares their story, helping others
                  truly understand and support their unique journey.
                </span>
              </div>
            </div>
          </div>
          <div className="home-mobile-second-container">
            <div>
              <img
                src={ChildImgOne}
                alt="circleLineOne"
                width={"82.16px"}
                height={"115.35px"}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    gap: 5,
                    paddingRight: "30px",
                  }}
                >
                  <img
                    src={headImg}
                    alt="img"
                    width={"18px"}
                    height={"18px"}
                    style={{ position: "relative", bottom: "4px" }}
                  />
                  <span className="home-page-text">Craft Your Own</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <span className="second-heading-text-home">
                    Journey Books
                  </span>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <p className="home-page-para-text">
                  Personalized stories celebrating every child's unique path
                </p>
              </div>
              <div
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <button className="custom-button" onClick={handleClick}>
                  Get Started Today
                </button>
              </div>
            </div>
            <div>
              <div className="circle-line-img-view">
                <img
                  src={circleLine}
                  className="img-home-page-circle"
                  alt="dashboardImg2"
                />
              </div>
              <div>
                <img
                  src={ChildImgTwo}
                  alt="circleLineOne"
                  width={"67.96px"}
                  height={"95.32px"}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Advocacy />
        </div>
        <div>
          <Video />
        </div>
        <div>
          <AboutUs />
        </div>
        <div>
          <Works
            parsedRoleId={parsedRoleId}
            formData={formData}
            isButtonDisabled={isButtonDisabled}
            setIsButtonDisabled={setIsButtonDisabled}
          />
        </div>
        <div>
          <Book />
        </div>
        <div>
          <FAQ />
        </div>
        <div>
          <Story />
        </div>
        <div>
          <Testimonial />
        </div>
        <div style={{ marginTop: "50px" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
