import React, { createContext, useState } from "react";

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    cardHolderName: "",
    cardNumber: "",
    IsUserProfile: null,
    validDate: "",
    cvv: "",
    UserProfilePhoto: ""
  });

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    cardHolderName: "",
    cardNumber: "",
    IsUserProfile: null,
    validDate: "",
    cvv: "",
    UserProfilePhoto: ""
  });
  const [data, setData] = useState([]);
  return (
    <FormContext.Provider value={{ data, setData, formData, setFormData, profileData, setProfileData }}>
      {children}
    </FormContext.Provider>
  );
};
