import React, { useEffect, useState } from "react";
import { useToast } from "../../../ToastContext";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import {
  resetPasswordAPI,
  sendOtp,
} from "../../../api/forgetpasswordAPI";
import { CircularProgress } from "@mui/material";
import LoginFirstImg from "../../../assets/images/loginBackgroundFirstImg.png";
import LoginSecondImg from "../../../assets/images/loginBackgroundSecondmg.png";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const toast = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [timer, setTimer] = useState(59);
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setLoading(false);
      toast.error("Please enter a valid email.");
      return;
    }
    const payload = {
      emailId: email,
    };
    try {
      const response = await sendOtp(payload);
      if (response && response?.status === 200) {
        toast.success("Otp Send successfully!");
        setStep(2);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    let interval;
    if (step === 2 && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResendOtp(true);
    }

    return () => clearInterval(interval);
  }, [step, timer]);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!otp || !password || !confirmPassword) {
      toast.error("Please fill in all required fields.");
      setLoading(false);
      return;
    }

    if (otp.length !== 6) {
      toast.error("Please enter the complete 6-digit OTP.");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      setLoading(false);
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a number."
      );
      setLoading(false);
      return;
    }

    try {
      const payload = {
        otp: otp,
        emailId: email,
        newPassword: password,
      };

      const response = await resetPasswordAPI(payload);

      if (response && response?.data && response?.status === 200) {
        setLoading(false);
        toast.success(response?.data?.message || "Password reset successful.");
        setStep(3);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || "An error occurred. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const handleBackToLogin = () => {
    navigate("/login");
  };

  const maskEmail = (email) => {
    if (!email) return "";
    const [localPart, domain] = email.split("@");
    if (!domain) return email;

    const domainParts = domain.split(".");
    const maskedLocal = localPart.slice(0, 2) + "****";
    const maskedDomain = domainParts.length > 1 ? `****.${domainParts.pop()}` : domain;

    return `${maskedLocal}@${maskedDomain}`;
  };


  const handleResendOtp = async () => {
    if (canResendOtp) {
      setTimer(59);
      setCanResendOtp(false);
      const payload = {
        emailId: email,
      };
      try {
        const response = await sendOtp(payload);
        if (response && response?.status === 200) {
          toast.success("Otp Send successfully!");
          setOtp("");
        } else {
          toast.error("Failed to resend OTP. Please try again.");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || "Error resending OTP.");
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white w-full max-w-6xl flex flex-col lg:flex-row shadow-lg rounded-lg overflow-hidden min-h-[90vh]">
        <div className="lg:w-1/2 flex items-center flex-col justify-between bg-customGreen">
          <div className="h-[30%] w-full flex justify-start lg:justify-start">
            <img
              src={LoginFirstImg}
              alt="LoginFirstImg"
              className="w-[130px] h-[130px] lg:w-[200.07px] lg:h-[200.02px]"
            />
          </div>
          <div className="text-center w-full h-[40%] mt-2 flex items-start flex-col justify-center px-4 lg:ml-10 lg:pl-16">
            <h3 className="text-2xl lg:text-4xl text-left font-semibold text-white">
              Forget password?
            </h3>
            <div className="w-1/2 lg:w-2/5 border-b-4 border-white mt-4"></div>
            <p className="text-customLiteWhite text-left mt-4 text-sm lg:text-base">
              Forget your old password and create a new one.
            </p>
          </div>
          <div className="h-[30%] w-full flex justify-end lg:justify-end items-end">
            <img
              src={LoginSecondImg}
              alt="LoginSecondImg"
              className="w-[130px] h-[130px] lg:w-[200px] lg:h-[200px]"
            />
          </div>
        </div>
        <div className="lg:w-1/2 p-8 md:p-24 bg-white flex items-center justify-center">
          {step === 1 && (
            <div className="w-full">
              <h1 className="font-poppins font-semibold text-base md:text-xl text-center">
                Forget password?
              </h1>
              <p className="text-customLiteGrey font-poppins text-xs md:text-sm text-center mb-3">
                Enter your email for instructions.
              </p>
              <form onSubmit={handleEmailSubmit} className="space-y-4 mt-10">
                <div className="flex flex-col">
                  <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                    Enter your email
                  </label>
                  <input
                    type="email"
                    className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]"
                    value={email}
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className="w-full cursor-pointer font-semibold font-Poppins text-xs md:text-sm bg-teal-600 text-white py-2 rounded-md hover:bg-teal-700 transition duration-300"
                >
                  {loading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    "Send 6-digit code"
                  )}
                </button>
                <div className="flex flex-row align-middle justify-center mt-1">
                  <IoIosArrowRoundBack size={20} />
                  <p className="text-center text-gray-600 font-Poppins font-medium text-xs md:text-sm">
                    <a href="/login">Back to log in </a>
                  </p>
                </div>
              </form>
            </div>
          )}

          {step === 2 && (
            <div className="w-full max-w-md mx-auto">
              <h1 className="font-poppins font-semibold text-xl text-center">
                Set New Password
              </h1>
              <p className="text-customLiteGrey font-normal font-poppins text-sm text-center mt-2">
                We sent a code to :{" "}
                <span className="font-poppins font-medium text-black">{maskEmail(email) || "no email"}</span>
              </p>
              <p className="text-customLiteGrey font-poppins text-sm text-center mb-6">
                Must be at least 8 characters.
              </p>
              <form onSubmit={handlePasswordSubmit} className="space-y-4">
                <div className="flex flex-col relative">
                  <label className="text-customLabel font-medium text-sm font-poppins">
                    Enter OTP:
                  </label>
                  <input
                    type="text"
                    maxLength="6"
                    className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    id="otp-input"
                  />
                </div>
                <div className="flex flex-col relative w-full items-start">
                  <p className="font-poppin font-medium text-center text-sm text-customLiteGrey cursor-pointer">
                    Didn’t receive the email?{" "}
                    <span
                      className={`font-poppin font-medium text-center text-sm ${canResendOtp
                        ? "text-teal-600 cursor-pointer"
                        : "text-teal-600 cursor-default"
                        }`}
                      onClick={handleResendOtp}
                    >
                      {canResendOtp ? "Click to resend" : `Resend in ${timer}s`}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col relative">
                  <label className="text-customLabel font-medium text-sm font-poppins">
                    New Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]"
                    value={password}
                    placeholder="New password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="absolute right-3 top-9 cursor-pointer text-gray-500"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </div>
                </div>

                {/* Confirm Password */}
                <div className="flex flex-col relative">
                  <label className="text-customLabel font-medium text-sm font-poppins">
                    Confirm Password
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]"
                    value={confirmPassword}
                    placeholder="Confirm password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <div
                    className="absolute right-3 top-9 cursor-pointer text-gray-500"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </div>
                </div>

                {/* Submit Button */}
                <button
                  disabled={loading}
                  type="submit"
                  className="w-full font-semibold bg-teal-600 text-white py-2 rounded-md mt-6"
                >
                  {loading ? <CircularProgress size={16} color="inherit" /> : "Set New Password"}
                </button>

                {/* Back to Login */}
                <div className="flex flex-row items-center justify-center mt-4">
                  <IoIosArrowRoundBack size={20} className="text-gray-600" />
                  <p className="text-center text-gray-600 font-poppins font-medium text-sm ml-1">
                    <a href="/login" className="hover:underline">Back to Login</a>
                  </p>
                </div>
              </form>
            </div>

          )}

          {step === 3 && (
            <div className="flex flex-col items-center justify-center text-center w-full">
              <FaCheckCircle
                size={54}
                className="text-teal-600 text-4xl mb-4"
              />
              <h2 className="text-[#090914] font-poppins font-semibold text-lg md:text-3xl text-center">
                Password reset successful !
              </h2>
              <p className="text-sm font-Poppins text-gray-600 mt-4">
                You have successfully reset your password. Please use your new
                password when logging in.
              </p>
              <button
                onClick={handleBackToLogin}
                className="mt-6 font-semibold font-Poppins text-xs md:text-sm bg-teal-600 text-white py-2 px-6 rounded-md hover:bg-teal-700 transition duration-300"
              >
                Back to login
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
