import React, { useContext, useEffect, useState } from "react";
import "../style/main.css";
import { SubRouting } from "../routes/Routing";
import HeadNav from "../components/nav-bar/HeadNav";
import SaveDraft from "./Form/SaveDraft";
import { useNavigation } from "../components/NavigationContext";
import { UserFormContext } from "../components/UserFormContext";
import { useToast } from "../ToastContext";
import { useNavigate } from "react-router-dom";
import { handleFormSubmitted } from "../api/book";
function Main() {
  const {
    formData,
    setFormData,
    setLoading,
    photochildFiles,
    setPhotochildFiles,
    activityCompanionFiles,
    setActivityCompanionFiles,
    childHospitalPhotoFiles,
    setChildHospitalPhotoFiles,
    challengingActivityFiles,
    setChallengingActivityFiles,
    childFriendsFamilyFiles,
    setchildFriendsFamilyFiles,
    childMentorHelpingFiles,
    setChildMentorHelpingFiles,
    childMentorHelpingSucceedFiles,
    setChildMentorHelpingSucceedFiles,
    childFacedChallengeFiles,
    setChildFacedChallengeFiles,
    childvictoriousHappyFiles,
    setChildvictoriousHappyFiles,
    childSupportiveFamilyFiles,
    setChildSupportiveFamilyFiles,
    childSupportiveFamilyTwoFiles,
    setChildSupportiveFamilyTwoFiles,
    childphotoMembersTeamFiles,
    setChildphotoMembersTeamFiles,
    childOneCloseEyesFiles,
    setChildOneCloseEyesFiles,
  } = useContext(UserFormContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { setIsFormDirty, draftModalIsOpen, setDraftModalIsOpen, handleCancel } = useNavigation();
  const userId = Number(localStorage.getItem("userId"));
  const [userData, setUserData] = useState(null);
  const userDataString = localStorage.getItem("userData");

  useEffect(() => {
    if (userDataString) {
      try {
        setUserData(JSON.parse(userDataString));
      } catch (error) {
        console.error("Error parsing userData from localStorage:", error);
      }
    }
  }, [userDataString]);

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      const isEdit = userData?.BookDetails?.Id ? true : false;
      const bookId = isEdit ? userData?.BookDetails?.Id : "";
      formDataToSend.append("name", `${formData.childFirstName} Story`);
      formDataToSend.append("userId", Number(userId));
      formDataToSend.append("isDraft", true);
      formDataToSend.append("isEdit", isEdit);
      formDataToSend.append("bookId", bookId);

      const jsonData = {
        childFirstName: formData.childFirstName,
        storyWriter: formData.storyWriter,
        photochildFiles: "",
        childFirstNameTwo: formData.childFirstNameTwo,
        favoriteActivity1: formData.favoriteActivity1,
        favoriteActivity2: formData.favoriteActivity2,
        activityCompanion: formData.activityCompanion,
        favoritePlace: formData.favoritePlace,
        activityReason: formData.activityReason,
        activityCompanionFiles: "",
        nicknameCaller: formData.nicknameCaller,
        nickname: formData.nickname,
        nicknameReason: formData.nicknameReason,
        birthTiming: formData.birthTiming,
        initialDiagnosis: formData.initialDiagnosis,
        diagnosisDetails: formData.diagnosisDetails,
        childHospitalPhotoFiles: "",
        challengingActivityFiles: "",
        additionalDiagnosis: formData.additionalDiagnosis,
        diagnosisDiscovery: formData.diagnosisDiscovery,
        childFriendsFamilyFiles: "",
        initialDiagnosisTwo: formData.initialDiagnosisTwo,
        diagnosisChallenges: formData.diagnosisChallenges,
        familyEmotion: formData.familyEmotion,
        emotionReason: formData.emotionReason,
        mentorRelationship: formData.mentorRelationship,
        mentorName: formData.mentorName,
        mentorAttributes: formData.mentorAttributes,
        mentorNameTwo: formData.mentorNameTwo,
        childMentorHelpingFiles: "",
        childMentorHelpingSucceedFiles: "",
        nameOfMentor: formData.nameOfMentor,
        mentorSkills: formData.mentorSkills,
        RelationShipMentor: formData.RelationShipMentor,
        ChildsName: formData.ChildsName,
        childFacedChallengeFiles: "",
        readerHelpChallenge: formData.readerHelpChallenge,
        readerSupportSuggestion: formData.readerSupportSuggestion,
        childvictoriousHappyFiles: "",
        nameOfMentorTwo: formData.nameOfMentorTwo,
        misunderstoodReaction: formData.misunderstoodReaction,
        childSupportiveFamilyFiles: "",
        advocacyHelp: formData.advocacyHelp,
        childSupportiveFamilyTwoFiles: "",
        supportTeam: formData.supportTeam,
        childphotoMembersTeamFiles: "",
        specificDetails: formData.specificDetails,
        childsNameTwo: formData.childsNameTwo,
        childAge: formData.childAge,
        childOneCloseEyesFiles: "",
      };
      formDataToSend.append("generalInformation", JSON.stringify(jsonData));

      photochildFiles.forEach((fileObj) => {
        formDataToSend.append("photochildFiles", fileObj.file);
      });

      activityCompanionFiles.forEach((fileObj) => {
        formDataToSend.append("activityCompanionFiles", fileObj.file);
      });

      childHospitalPhotoFiles.forEach((fileObj) => {
        formDataToSend.append("childHospitalPhotoFiles", fileObj.file);
      });
      challengingActivityFiles.forEach((fileObj) => {
        formDataToSend.append("challengingActivityFiles", fileObj.file);
      });
      childFriendsFamilyFiles.forEach((fileObj) => {
        formDataToSend.append("childFriendsFamilyFiles", fileObj.file);
      });
      childMentorHelpingFiles.forEach((fileObj) => {
        formDataToSend.append("childMentorHelpingFiles", fileObj.file);
      });
      childMentorHelpingSucceedFiles.forEach((fileObj) => {
        formDataToSend.append("childMentorHelpingSucceedFiles", fileObj.file);
      });
      childFacedChallengeFiles.forEach((fileObj) => {
        formDataToSend.append("childFacedChallengeFiles", fileObj.file);
      });
      childvictoriousHappyFiles.forEach((fileObj) => {
        formDataToSend.append("childvictoriousHappyFiles", fileObj.file);
      });
      childSupportiveFamilyFiles.forEach((fileObj) => {
        formDataToSend.append("childSupportiveFamilyFiles", fileObj.file);
      });
      childSupportiveFamilyTwoFiles.forEach((fileObj) => {
        formDataToSend.append("childSupportiveFamilyTwoFiles", fileObj.file);
      });
      childphotoMembersTeamFiles.forEach((fileObj) => {
        formDataToSend.append("childphotoMembersTeamFiles", fileObj.file);
      });
      childOneCloseEyesFiles.forEach((fileObj) => {
        formDataToSend.append("childOneCloseEyesFiles", fileObj.file);
      });
      setLoading(true);
      const response = await handleFormSubmitted(formDataToSend);

      if (response?.status === 200 && response.data) {
        toast.success("Save Draft!");
        setPhotochildFiles([]);
        setActivityCompanionFiles([]);
        setChildHospitalPhotoFiles([]);
        setChallengingActivityFiles([]);
        setchildFriendsFamilyFiles([]);
        setChildMentorHelpingFiles([]);
        setChildMentorHelpingSucceedFiles([]);
        setChildFacedChallengeFiles([]);
        setChildvictoriousHappyFiles([]);
        setChildSupportiveFamilyFiles([]);
        setChildSupportiveFamilyTwoFiles([]);
        setChildphotoMembersTeamFiles([]);
        setChildOneCloseEyesFiles([]);
        setFormData({});
        setDraftModalIsOpen(false)
        setIsFormDirty(false)
        setUserData(null)
        navigate('/userdashboard')
        setLoading(false);
        localStorage.removeItem("userData");
      } else {
        toast.error("Error during submission. Please try again.");
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("Error during submission. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="main">
      <div className="head-component">
        <HeadNav />
      </div>
      <div className="body-component">
        <SubRouting />
      </div>
      {draftModalIsOpen && (
        <SaveDraft
          isOpen={draftModalIsOpen}
          onClose={handleCancel}
          onSaveDraft={handleSaveDraft}
        />
      )}
    </div>
  );
}
export default Main;
