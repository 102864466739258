import React, { useEffect } from "react";
import { RiCloseLargeLine } from "react-icons/ri";

const ProfileInfo = ({
  formData,
  handleInputChange,
  sideImg,
  handleClose,
  profileData,
  setProfileData,
}) => {
  useEffect(() => {
    setProfileData(formData);
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center bg-white shadow-md p-4 fixed w-full z-50">
        <div className="flex items-center gap-3">
          <img className="cursor-pointer" onClick={handleClose} src={sideImg} width={"32px"} height={"32px"} alt={"sideImg"} />
          <h2 className="text-lg text-customBlackHeadColor font-SegoeUI font-semibold">
            Edit Personal Information
          </h2>
        </div>
      </div>
      <div className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-28">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              placeholder="First Name"
              className="mt-1 block w-full border border-gray-300 h-12 rounded-md pl-2 bg-[#FAFAFA] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={profileData.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              placeholder="Last Name"
              className="mt-1 block w-full h-12 border border-gray-300 rounded-md pl-2 bg-[#FAFAFA] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={profileData.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="text"
              placeholder="Email"
              className="mt-1 block w-full h-12 border border-gray-300 rounded-md pl-2 bg-[#FAFAFA] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={profileData.email}
              disabled
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
              type="text"
              placeholder="Phone Number"
              className="mt-1 block w-full h-12 border border-gray-300 rounded-md pl-2 bg-[#FAFAFA] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={profileData.phoneNumber}
              onChange={(e) => {
                handleInputChange("phoneNumber", e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
