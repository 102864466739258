import { Route, Routes } from "react-router-dom";
import React, { useCallback, useContext, useEffect } from "react";
import AboutUs from "../pages/About Us/AboutUs";
import Main from "../pages/main";
import Works from "../pages/ItWorks/Works";
import Testimonials from "../pages/Testimonials/Testimonials";
import ContactUs from "../pages/ContactUs/ContactUs";
import Form from "../pages/Form/Form";
import FAQMain from "../pages/FAQ/FAQMain";
import Privacypolicy from "../pages/Privacypolicy/Privacypolicy";
import Login from "../pages/Login/Login";
import SignUp from "../pages/Login/Sign-up/SignUp";
import ForgetPassword from "../pages/Login/ForgetPassword/ForgetPassword";
import Home from "../pages/Home/Home";
import UserDashboard from "../pages/Dashboard/UserDashboard/UserDashboard";
import AdminDashboard from "../pages/Dashboard/AdminDashboard/AdminDashboard";
import Profile from "../pages/Profile/Profile";
import ScrollToTop from "./ScrollToTop";
import { FormContext } from "../components/FormContext";
import { useToast } from "../ToastContext";
import { UserDetails } from "../api/userProfile";
import Reviewer from "../pages/Dashboard/Reviewer/Reviewer";
import MailURL from "../pages/ApproveOrdersModal/MailURL";
import ProtectedRoute from "./ProtectedRoute";

export const Routing = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/*" element={<Main />} />
    </Routes>
  );
};

export const SubRouting = () => {
  const { setFormData } = useContext(FormContext);
  const userId = localStorage.getItem("userId");
  const toast = useToast();

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await UserDetails(userId);
      if (response && response.data) {
        const {
          FirstName,
          LastName,
          EmailId,
          PhoneNumber,
          Address1,
          Address2,
          City,
          State,
          Country,
          IsUserProfile,
          UserProfilePhoto,
        } = response.data;

        setFormData((prev) => ({
          ...prev,
          firstName: FirstName || "",
          lastName: LastName || "",
          email: EmailId || "",
          phoneNumber: PhoneNumber || "",
          IsUserProfile: IsUserProfile || null,
          address1: Address1 || "",
          address2: Address2 || "",
          city: City || "",
          state: State || "",
          country: Country || "",
          UserProfilePhoto: UserProfilePhoto || "",
        }));
      }
    } catch (error) {
      toast.error("Failed to fetch user details. Please try again.");
    }
  }, [setFormData, userId]);

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId, fetchUserDetails]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="works" element={<Works />} />
        <Route path="testimonials" element={<Testimonials />} />
        <Route path="contactUs" element={<ContactUs />} />
        <Route path="form" element={<Form />} />
        <Route path="faq" element={<FAQMain />} />
        <Route path="privacypolicy" element={<Privacypolicy />} />
        <Route path="profile" element={<Profile />} />
        <Route path="admindashboard" element={<AdminDashboard />} />
        <Route path="userdashboard" element={<UserDashboard />} />
        <Route path="reviewer" element={<Reviewer />} />
        <Route path="view-details/:bookId" element={
          <ProtectedRoute>
            <MailURL />
          </ProtectedRoute>
        } />
      </Routes>
    </>
  );
};
