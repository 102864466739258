import React, { createContext, useState } from "react";

export const UserFormContext = createContext();

export const UserFormProvider = ({ children }) => {
	const [formData, setFormData] = useState({
		childFirstName: "",
		storyWriter: "",
		childFirstNameTwo: "",
		childAge: "",
		favoriteActivity1: "",
		favoriteActivity2: "",
		activityCompanion: "",
		favoritePlace: "",
		activityReason: "",
		nicknameCaller: "",
		nickname: "",
		nicknameReason: "",
		birthTiming: "",
		initialDiagnosis: "",
		diagnosisDetails: "",
		additionalDiagnosis: "",
		initialDiagnosisTwo: "",
		diagnosisDiscovery: "",
		mentorAttributes: "",
		diagnosisChallenges: "",
		familyEmotion: "",
		emotionReason: "",
		mentorRelationship: "",
		mentorName: "",
		nameOfMentor: "",
		mentorSkills: "",
		readerHelpChallenge: "",
		RelationShipMentor: "",
		ChildsName: "",
		mentorNameTwo: "",
		readerSupportSuggestion: "",
		nameOfMentorTwo: "",
		misunderstoodReaction: "",
		advocacyHelp: "",
		childsNameTwo: "",
		supportTeam: "",
		specificDetails: "",
	});

	const [loading, setLoading] = useState(false);
	const [showUploadError, setShowUploadError] = useState(false);
	const [photochildFiles, setPhotochildFiles] = useState([]);
	const [activityCompanionFiles, setActivityCompanionFiles] = useState([]);
	const [childHospitalPhotoFiles, setChildHospitalPhotoFiles] = useState([]);
	const [challengingActivityFiles, setChallengingActivityFiles] = useState([]);
	const [childFriendsFamilyFiles, setchildFriendsFamilyFiles] = useState([]);
	const [childMentorHelpingFiles, setChildMentorHelpingFiles] = useState([]);
	const [childMentorHelpingSucceedFiles, setChildMentorHelpingSucceedFiles] = useState([]);
	const [childFacedChallengeFiles, setChildFacedChallengeFiles] = useState([]);
	const [childvictoriousHappyFiles, setChildvictoriousHappyFiles] = useState([]);
	const [childSupportiveFamilyFiles, setChildSupportiveFamilyFiles] = useState([]);
	const [childSupportiveFamilyTwoFiles, setChildSupportiveFamilyTwoFiles] = useState([]);
	const [childphotoMembersTeamFiles, setChildphotoMembersTeamFiles] = useState([]);
	const [childOneCloseEyesFiles, setChildOneCloseEyesFiles] = useState([]);

	return (
		<UserFormContext.Provider
			value={{
				formData,
				setFormData,
				loading,
				setLoading,
				showUploadError,
				setShowUploadError,
				photochildFiles,
				setPhotochildFiles,
				activityCompanionFiles,
				setActivityCompanionFiles,
				childHospitalPhotoFiles,
				setChildHospitalPhotoFiles,
				challengingActivityFiles,
				setChallengingActivityFiles,
				childFriendsFamilyFiles,
				setchildFriendsFamilyFiles,
				childMentorHelpingFiles,
				setChildMentorHelpingFiles,
				childMentorHelpingSucceedFiles,
				setChildMentorHelpingSucceedFiles,
				childFacedChallengeFiles,
				setChildFacedChallengeFiles,
				childvictoriousHappyFiles,
				setChildvictoriousHappyFiles,
				childSupportiveFamilyFiles,
				setChildSupportiveFamilyFiles,
				childSupportiveFamilyTwoFiles,
				setChildSupportiveFamilyTwoFiles,
				childphotoMembersTeamFiles,
				setChildphotoMembersTeamFiles,
				childOneCloseEyesFiles,
				setChildOneCloseEyesFiles,
			}}
		>
			{children}
		</UserFormContext.Provider>
	);
};
