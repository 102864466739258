import React, { useCallback, useEffect, useState } from "react";
import ApproveOrdersModal from "../../ApproveOrdersModal/ApproveOrdersModal";
import Footer from "../../../components/nav-bar/Footer";
import {
  getUserBookDetails,
  getviewUserDetails,
} from "../../../api/userProfile";
import moment from "moment";
import { UpdateReviewAPI } from "../../../api/book";
import { useToast } from "../../../ToastContext";
import * as Role from "../../../Entities/Role";
import * as Status from "../../../Entities/RoleStatus";
import * as Order_Status from "../../../Entities/Order-Status";
import { FiEye } from "react-icons/fi";

function Reviewer() {
  const toast = useToast();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userViewDetails, setVieUserDetails] = useState(null);
  const [data, setData] = useState([]);
  const userId = Number(localStorage.getItem("userId"));
  const roleId = localStorage.getItem("RoleId");

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await getUserBookDetails(userId, false);
      if (response && response.data) {
        setData(response.data);
      } else {
        console.error("No data found in response.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId, fetchUserDetails]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newSelectedOrders = newSelectAll
      ? data.reduce((acc, order) => {
        acc[order.Id] = true;
        return acc;
      }, {})
      : {};
    setSelectedOrders(newSelectedOrders);
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prevState) => {
      const newState = { ...prevState };
      if (newState[orderId]) {
        delete newState[orderId];
      } else {
        newState[orderId] = true;
      }
      return newState;
    });
  };

  const handleClick = async (user) => {
    try {
      const response = await getviewUserDetails(user);
      if (response && response.data) {
        setIsModalOpen(true);
        setVieUserDetails(response.data);
      } else {
        toast.error("No data found in response.");
      }
    } catch (error) {
      toast.error("Error fetching user details:", error);
      console.error("Error fetching user details:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleReviewer = async () => {
    const bookIds = Object.keys(selectedOrders).filter(
      (id) => selectedOrders[id]
    );
    const bookId = bookIds[0];
    const newStatus =
      roleId === Role.REVIEWER ? Status.REVIEWE : Status.APPROVED;
    const payload = {
      userId: userId,
      bookId: bookId,
      reviewStatus: newStatus,
      reason: null,
    };
    try {
      const response = await UpdateReviewAPI(payload);
      if (response && response.data && response.status === 200) {
        setSelectedOrders({});
        setSelectAll(false);
        await fetchUserDetails();
        toast.success("Review updated successfully");
      }
    } catch (error) {
      console.error("Error updating review:", error);
      toast.error("An error occurred while updating the review");
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case Status.WAITING_APPROVAL:
        return {
          text: Order_Status.WAITING_APPROVAL,
          color: "bg-orange-100 text-orange-600",
        };
      case Status.ACTIVE:
        return {
          text: Order_Status.ACTIVE,
          color: "bg-green-100 text-green-600",
        };
      case Status.INACTIVE:
        return {
          text: Order_Status.INACTIVE,
          color: "bg-gray-100 text-gray-600",
        };
      case Status.REJECTED:
        return {
          text: Order_Status.REJECTED,
          color: "bg-red-100 text-red-600",
        };
      case Status.REVIEWE:
        return {
          text: Order_Status.REVIEWE,
          color: "bg-blue-100 text-blue-600",
        };
      case Status.PRINTING:
        return {
          text: Order_Status.PRINTING,
          color: "bg-green-100 text-green-600",
        };
      case Status.SHIPPING:
        return {
          text: Order_Status.SHIPPING,
          color: "bg-green-100 text-green-600",
        };
      case Status.APPROVED:
        return {
          text: Order_Status.APPROVED,
          color: "bg-green-100 text-green-600",
        };
      default:
        return {
          text: "-",
        };
    }
  };

  return (
    <div className="w-full h-screen flex items-center flex-col bg-white">
      <div className="text-left w-full mt-10 px-16">
        <h1 className="text-xl sm:text-2xl lg:text-3xl font-normal text-customGreyBase font-Merriweather">
          Dashboard
        </h1>
      </div>
      <div className="w-11/12 sm:w-10/12 md:w-9/12 lg:w-11/12 flex align-middle justify-center mt-10">
        <div className="border border-gray-300 rounded-lg shadow-sm w-full">
          <div className="flex flex-col sm:flex-row justify-between px-4 py-3">
            <h2 className="text-lg sm:text-xl font-semibold text-gray-700">
              Active/Approved Orders
            </h2>
          </div>
          <div className="overflow-y-auto max-h-80 border border-gray-200 rounded-lg">
            <table className="min-w-full table-fixed border-collapse">
              <thead className="bg-white sticky top-0 z-10 border-b border-gray-300 shadow-sm">
                <tr>
                  <th className="py-2 sm:py-3 px-4 text-left font-medium text-gray-600">
                    <input
                      type="checkbox"
                      className="rounded-sm"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-left font-semibold text-gray-500">
                    Book ID
                  </th>
                  <th className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-left font-semibold text-gray-500">
                    Customer Name
                  </th>
                  <th className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-left font-semibold text-gray-500">
                    Book Title
                  </th>
                  <th className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-left font-semibold text-gray-500">
                    Order Date
                  </th>
                  <th className="py-2 sm:text-sm text-xs sm:py-3 px-6 text-left font-semibold text-gray-500">
                    Status
                  </th>
                  <th className="py-3 sm:text-sm text-xs px-4 sm:px-3 text-left font-semibold text-gray-500">
                    View
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.length > 0 ? (
                  data.map((order, index) => (
                    <tr className="border-b border-gray-200" key={order.Id}>
                      <td className="py-2 sm:py-3 px-4">
                        <input
                          type="checkbox"
                          className="rounded-sm"
                          checked={!!selectedOrders[order.Id]}
                          onChange={() => handleSelectOrder(order.Id)}
                        />
                      </td>
                      <td className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-gray-700 font-normal">
                        {order.Id}
                      </td>
                      <td className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-gray-700 font-normal">
                        {order.CustomerName}
                      </td>
                      <td className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-gray-700 font-normal">
                        {order.Name}
                      </td>
                      <td className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-gray-700 font-normal">
                        {moment(order.CreatedDateTime).format("MM/DD/YYYY")}
                      </td>
                      <td className="py-2 sm:text-sm text-xs sm:py-3 px-4 font-normal">
                        <span
                          className={`${getStatusStyle(order.ReviewStatus).color
                            } py-1 px-3 rounded-full sm:text-sm text-xs`}
                        >
                          {getStatusStyle(order.ReviewStatus).text || "-"}
                        </span>
                      </td>
                      <td className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-gray-700 font-normal">
                        <span className="text-gray-600 cursor-pointer" onClick={() => handleClick(order.Id)}>View</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="w-full">
                    <td
                      colSpan="6"
                      className="py-4 text-center relative left-3 text-gray-500 text-sm w-full"
                    >
                      No orders
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ApproveOrdersModal
        fetchUserDetails={fetchUserDetails}
        handleReviewer={handleReviewer}
        userViewDetails={userViewDetails}
        isOpen={isModalOpen}
        closeModal={closeModal}
      />
      <div className="mt-44 w-full">
        <Footer />
      </div>
    </div>
  );
}

export default Reviewer;
