import React from "react";
import * as Status from "../../Entities/RoleStatus";
import * as Order_Status from "../../Entities/Order-Status";
import sideImg from "../../assets/images/sidebar-left.png";

function MailHeader({ userViewDetails }) {

	const getStatusStyle = (status) => {
		switch (status) {
			case Status.WAITING_APPROVAL:
				return {
					text: Order_Status.WAITING_APPROVAL,
					color: "bg-orange-100 text-orange-600",
				};
			case Status.ACTIVE:
				return {
					text: Order_Status.ACTIVE,
					color: "bg-green-100 text-green-600",
				};
			case Status.INACTIVE:
				return {
					text: Order_Status.INACTIVE,
					color: "bg-gray-100 text-gray-600",
				};
			case Status.REJECTED:
				return {
					text: Order_Status.REJECTED,
					color: "bg-red-100 text-red-600",
				};
			case Status.REVIEWE:
				return {
					text: Order_Status.REVIEWE,
					color: "bg-blue-100 text-blue-600",
				};
			case Status.PRINTING:
				return {
					text: Order_Status.PRINTING,
					color: "bg-green-100 text-green-600",
				};
			case Status.SHIPPING:
				return {
					text: Order_Status.SHIPPING,
					color: "bg-green-100 text-green-600",
				};
			case Status.APPROVED:
				return {
					text: Order_Status.APPROVED,
					color: "bg-green-100 text-green-600",
				};
			default:
				return {
					text: "-",
				};
		}
	};

	const statusInfo = getStatusStyle(userViewDetails?.BookDetails?.ReviewStatus);

	return (
		<div className="flex justify-between items-center bg-white shadow-md p-4 fixed w-full z-10">
			<div className="flex items-center gap-3">
				<img src={sideImg} width={"32px"} height={"32px"} alt={"sideImg"} />
				<h2 className="text-base text-customBlackHeadColor font-SegoeUI font-semibold">
					Order Details
				</h2>
				<div className="bg-customGreen text-white md:rounded-full rounded-md p-1 lg:rounded-full ml-2 md:ml-4 w-auto  lg:px-4 py-1">
					<span className="text-xs md:text-sm lg:text-base font-medium font-inter text-white">
						ID #{userViewDetails?.BookDetails?.Id}
					</span>
				</div>

				<div
					className={`ml-2 md:ml-3 lg:ml-4 px-2 md:px-3 lg:px-4 py-1 rounded-full ${statusInfo.color}`}
				>
					<span className="text-xs md:text-sm lg:text-base font-medium">
						{statusInfo.text}
					</span>
				</div>
			</div>
		</div>
	);
}

export default MailHeader;
