import React from "react";
import ChildImg from "../../assets/images/Mindmap_Complete_Wide.png";

function Advocacy() {
  return (
    <div className="flex flex-col items-center justify-center mt-5 px-5 w-full">
      <div className="flex items-center justify-center p-2 w-full"> 
        <div className="w-full flex items-center justify-center">
          <img
            src={ChildImg}
            className="w-full h-auto sm:w-[280px] sm:h-[300px] md:w-[700px] md:h-[500px] lg:w-[1240px] lg:h-[700px]"
            alt="dashboardImg2"
          />
        </div>
      </div>
    </div>
  );
}

export default Advocacy;