import React from "react";
import { FiMapPin } from "react-icons/fi";

function ShippingBookInfo({ userViewDetails }) {

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-[#FCFCFD] p-4 rounded-lg border border-gray-200 mb-4">
      {/* <div>
        <h4 className="flex items-center text-sm font-semibold mb-2">
          <FiMapPin size={16} className="mr-2" />
          <span className="font-poppins text-sm font-medium text-black">
            Shipping Address
          </span>
        </h4>
        <p className="font-normal font-Poppins text-black text-sm">
          {userViewDetails?.UserProfile?.Address1}
          <br />
          {userViewDetails?.UserProfile?.City}.{" "}
          {userViewDetails?.UserProfile?.State}.
        </p>
      </div> */}
      <div>
        <h4 className="flex items-center text-sm font-semibold mb-2">
          <FiMapPin size={16} className="mr-2" />
          <span className="font-poppins text-sm font-medium text-black">
            Book Information
          </span>
        </h4>
        <p className="text-sm text-customTextGrey font-normal font-Poppins">
          Title:{" "}
          <span className="font-normal font-Poppins text-black text-sm">
            {userViewDetails?.BookDetails?.Name}
          </span>
        </p>
      </div>
    </div>
  );
}

export default ShippingBookInfo;
