import React, { useState, useEffect } from "react";
import InputField from "./InputField";
import { RemoveUploadImgAPI } from "../../api/book";
import { useToast } from "../../ToastContext";
import * as Status from "../../Entities/RoleStatus";
import * as Role from "../../Entities/Role";
import { IoCloseSharp } from "react-icons/io5";

const StoryInformationForm = ({
  uploadedImages,
  userViewDetails,
  setUploadedImages,
  roleId,
}) => {
  const [formData, setFormData] = useState({
    childFirstName: "",
    storyWriter: "",
    childFirstNameTwo: "",
    favoriteActivity1: "",
    favoriteActivity2: "",
    activityCompanion: "",
    favoritePlace: "",
    activityReason: "",
    nicknameCaller: "",
    nickname: "",
    nicknameReason: "",
    birthTiming: "",
    initialDiagnosis: "",
    diagnosisDetails: "",
    additionalDiagnosis: "",
    diagnosisDiscovery: "",
    initialDiagnosisTwo: "",
    diagnosisChallenges: "",
    familyEmotion: "",
    emotionReason: "",
    mentorRelationship: "",
    mentorName: "",
    mentorAttributes: "",
    mentorNameTwo: "",
    nameOfMentor: "",
    mentorSkills: "",
    RelationShipMentor: "",
    ChildsName: "",
    readerHelpChallenge: "",
    readerSupportSuggestion: "",
    nameOfMentorTwo: "",
    misunderstoodReaction: "",
    advocacyHelp: "",
    supportTeam: "",
    specificDetails: "",
    childsNameTwo: "",
    childAge: "",
  });
  const toast = useToast();

  useEffect(() => {
    if (userViewDetails?.BookDetails?.GeneralInformation) {
      const generalInfo = userViewDetails?.BookDetails?.GeneralInformation;
      setFormData((prevFormData) => ({
        ...prevFormData,
        childFirstName: generalInfo.childFirstName || "",
        storyWriter: generalInfo.storyWriter || "",
        childFirstNameTwo: generalInfo.childFirstNameTwo || "",
        favoriteActivity1: generalInfo.favoriteActivity1 || "",
        favoriteActivity2: generalInfo.favoriteActivity2 || "",
        activityCompanion: generalInfo.activityCompanion || "",
        favoritePlace: generalInfo.favoritePlace || "",
        activityReason: generalInfo.activityReason || "",
        nicknameCaller: generalInfo.nicknameCaller || "",
        nickname: generalInfo.nickname || "",
        nicknameReason: generalInfo.nicknameReason || "",
        birthTiming: generalInfo.birthTiming || "",
        initialDiagnosis: generalInfo.initialDiagnosis || "",
        diagnosisDetails: generalInfo.diagnosisDetails || "",
        additionalDiagnosis: generalInfo.additionalDiagnosis || "",
        diagnosisDiscovery: generalInfo.diagnosisDiscovery || "",
        initialDiagnosisTwo: generalInfo.initialDiagnosisTwo || "",
        diagnosisChallenges: generalInfo.diagnosisChallenges || "",
        familyEmotion: generalInfo.familyEmotion || "",
        emotionReason: generalInfo.emotionReason || "",
        mentorRelationship: generalInfo.mentorRelationship || "",
        mentorName: generalInfo.mentorName || "",
        mentorAttributes: generalInfo.mentorAttributes || "",
        mentorNameTwo: generalInfo.mentorNameTwo || "",
        nameOfMentor: generalInfo.nameOfMentor || "",
        mentorSkills: generalInfo.mentorSkills || "",
        RelationShipMentor: generalInfo.RelationShipMentor || "",
        ChildsName: generalInfo.ChildsName || "",
        readerHelpChallenge: generalInfo.readerHelpChallenge || "",
        readerSupportSuggestion: generalInfo.readerSupportSuggestion || "",
        nameOfMentorTwo: generalInfo.nameOfMentorTwo || "",
        misunderstoodReaction: generalInfo.misunderstoodReaction || "",
        advocacyHelp: generalInfo.advocacyHelp || "",
        supportTeam: generalInfo.supportTeam || "",
        specificDetails: generalInfo.specificDetails || "",
        childsNameTwo: generalInfo.childsNameTwo || "",
        childAge: generalInfo.childAge || "",
      }));
    }

    if (userViewDetails?.BookPhotos) {
      const images = userViewDetails?.BookPhotos.map((photo) => ({
        src: photo.BlobPath,
        alt: `Image for BookId ${photo?.BookId}`,
        id: photo.Id,
      }));
      setUploadedImages(images);
    }
  }, [userViewDetails]);

  const handleRemoved = async (event, imageId) => {
    event.preventDefault();
    try {
      const payload = { bookPhotoIds: [imageId] };
      const response = await RemoveUploadImgAPI(payload);
      if (response && response.data) {
        toast.success("Image removed successfully");
        setUploadedImages((prev) => prev.filter((img) => img.id !== imageId));
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An unexpected error occurred while removing the image.";
      toast.error(
        errorMessage || "An unexpected error occurred while removing the image."
      );
      console.error("Error removing image:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="bg-white p-2">
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            label="What is the child's first name?"
            placeholder="What is the child's first name?"
            value={formData.childFirstName}
            onChange={handleChange}
            name="childFirstName"
          />
          <InputField
            label="Who is writing the story?"
            placeholder="Who is writing the story?"
            value={formData.storyWriter}
            onChange={handleChange}
            name="storyWriter"
          />
          <InputField
            label="What is the child's first name?"
            placeholder="What is the child's first name?"
            value={formData.childFirstNameTwo}
            onChange={handleChange}
            name="childFirstNameTwo"
          />
          <InputField
            label="What is one activity that the child enjoys?"
            placeholder="What is one activity that the child enjoys?"
            value={formData.favoriteActivity1}
            onChange={handleChange}
            name="favoriteActivity1"
          />
          <InputField
            label="What is another favorite activity?"
            placeholder="What is another favorite activity?"
            value={formData.favoriteActivity2}
            onChange={handleChange}
            name="favoriteActivity2"
          />
          <InputField
            label="Who is the child with when they participate in this activity?"
            placeholder="Who is the child with when they participate in this activity?"
            value={formData.activityCompanion}
            onChange={handleChange}
            name="activityCompanion"
          />
          <InputField
            label="What is a favorite place to visit?"
            placeholder="What is a favorite place to visit?"
            value={formData.favoritePlace}
            onChange={handleChange}
            name="favoritePlace"
          />
          <InputField
            label="Why is this a favorite activity?"
            placeholder="Why is this a favorite activity?"
            value={formData.activityReason}
            onChange={handleChange}
            name="activityReason"
          />
          <InputField
            label="Who calls the child by a nickname? Family/friends?"
            placeholder="Who calls the child by a nickname? Family/friends?"
            value={formData.nicknameCaller}
            onChange={handleChange}
            name="nicknameCaller"
          />
          <InputField
            label="What is the nickname for the child?"
            placeholder="What is the nickname for the child?"
            value={formData.nickname}
            onChange={handleChange}
            name="nickname"
          />
          <InputField
            label="Why did they choose that nickname?"
            placeholder="Why did they choose that nickname?"
            value={formData.nicknameReason}
            onChange={handleChange}
            name="nicknameReason"
          />
          <InputField
            label="How many weeks early was the child born or full term?"
            placeholder="How many weeks early was the child born or full term?"
            value={formData.birthTiming}
            onChange={handleChange}
            name="birthTiming"
          />
          <InputField
            label="What is the diagnosis that was first discovered?"
            placeholder="What is the diagnosis that was first discovered?"
            value={formData.initialDiagnosis}
            onChange={handleChange}
            name="initialDiagnosis"
          />
          <InputField
            label="You may add details of what was needed as a result of the diagnosis in
          one sentence (from the child's perspective). You may include a second
          sentence to state briefly the challenges this caused for the family.)"
            placeholder="You may add details of what was needed as a result of the diagnosis in
          one sentence (from the child's perspective). You may include a second
          sentence to state briefly the challenges this caused for the family."
            value={formData.diagnosisDetails}
            onChange={handleChange}
            name="diagnosisDetails"
          />
          <InputField
            label="What is an additional challenge or diagnosis that was discovered?"
            placeholder="What is an additional challenge or diagnosis that was discovered?"
            value={formData.additionalDiagnosis}
            onChange={handleChange}
            name="additionalDiagnosis"
          />
          <InputField
            label="How did the family or doctors learn about the additional
          challenge/diagnosis?"
            placeholder="How did the family or doctors learn about the additional
          challenge/diagnosis?"
            value={formData.diagnosisDiscovery}
            onChange={handleChange}
            name="diagnosisDiscovery"
          />
          <InputField
            label="What is the diagnosis that was first discovered?"
            placeholder="What is the diagnosis that was first discovered?"
            value={formData.initialDiagnosisTwo}
            onChange={handleChange}
            name="initialDiagnosisTwo"
          />
          <InputField
            label="What are a few difficulties or challenges faced as a result of the
          diagnosis?"
            placeholder="What are a few difficulties or challenges faced as a result of the
          diagnosis?"
            value={formData.diagnosisChallenges}
            onChange={handleChange}
            name="diagnosisChallenges"
          />
          <InputField
            label="What is a feeling the family associates in helping people understand
          the difficulties/diagnosis?"
            placeholder="What is a feeling the family associates in helping people understand
          the difficulties/diagnosis?"
            value={formData.familyEmotion}
            onChange={handleChange}
            name="familyEmotion"
          />
          <InputField
            label="Why that emotional response? Because…"
            placeholder="Why that emotional response? Because…"
            value={formData.emotionReason}
            onChange={handleChange}
            name="emotionReason"
          />
          <InputField
            label="What is the relationship of a mentor who has helped most on this
          journey?"
            placeholder="What is the relationship of a mentor who has helped most on this
          journey?"
            value={formData.mentorRelationship}
            onChange={handleChange}
            name="mentorRelationship"
          />
          <InputField
            label="What is the name of the mentor?"
            placeholder="What is the name of the mentor?"
            value={formData.mentorName}
            onChange={handleChange}
            name="mentorName"
          />
          <InputField
            label="List 1-2 attributes of the mentor."
            placeholder="List 1-2 attributes of the mentor."
            value={formData.mentorAttributes}
            onChange={handleChange}
            name="mentorAttributes"
          />
          <InputField
            label="What is the name of the mentor?"
            placeholder="What is the name of the mentor?"
            value={formData.mentorNameTwo}
            onChange={handleChange}
            name="mentorNameTwo"
          />
          <InputField
            label="Name of mentor"
            placeholder="Name of mentor"
            value={formData.nameOfMentor}
            onChange={handleChange}
            name="nameOfMentor"
          />
          <InputField
            label="Mention a task/skill (up to 3) the mentor helps the child with."
            placeholder="Mention a task/skill (up to 3) the mentor helps the child with."
            value={formData.mentorSkills}
            onChange={handleChange}
            name="mentorSkills"
          />
          <InputField
            label="Relationship of mentor"
            placeholder="Relationship of mentor"
            value={formData.RelationShipMentor}
            onChange={handleChange}
            name="RelationShipMentor"
          />
          <InputField
            label="Child's name"
            placeholder="Child's name"
            value={formData.ChildsName}
            onChange={handleChange}
            name="ChildsName"
          />
          <InputField
            label="Name a challenge the child faces that the reader can help with.?"
            placeholder="Name a challenge the child faces that the reader can help with.?"
            value={formData.readerHelpChallenge}
            onChange={handleChange}
            name="readerHelpChallenge"
          />
          <InputField
            label="Describe the way the reader can support the child through the
          challenge."
            placeholder="Describe the way the reader can support the child through the
          challenge."
            value={formData.readerSupportSuggestion}
            onChange={handleChange}
            name="readerSupportSuggestion"
          />
          <InputField
            label="Name of mentor."
            placeholder="Name of mentor."
            value={formData.nameOfMentorTwo}
            onChange={handleChange}
            name="nameOfMentorTwo"
          />
          <InputField
            label="What is the child's reaction that is commonly misunderstood?"
            placeholder="What is the child's reaction that is commonly misunderstood?"
            value={formData.misunderstoodReaction}
            onChange={handleChange}
            name="misunderstoodReaction"
          />
          <InputField
            label="How can you suggest others help in being advocates?"
            placeholder="How can you suggest others help in being advocates?"
            value={formData.advocacyHelp}
            onChange={handleChange}
            name="advocacyHelp"
          />
          <InputField
            label="Who are the people on the child's support team?"
            placeholder="Who are the people on the child's support team?"
            value={formData.supportTeam}
            onChange={handleChange}
            name="supportTeam"
          />
          <InputField
            label="Tell the reader about any specific details about the child including
          tools or tips for supporting the child's needs."
            placeholder="Tell the reader about any specific details about the child including
          tools or tips for supporting the child's needs."
            value={formData.specificDetails}
            onChange={handleChange}
            name="specificDetails"
          />
          <InputField
            label="What is the child's name?"
            placeholder="What is the child's name?"
            value={formData.childsNameTwo}
            onChange={handleChange}
            name="childsNameTwo"
          />
          <InputField
            label="What is the child's age?"
            placeholder="What is the child's age?"
            value={formData.childAge}
            onChange={handleChange}
            name="childAge"
          />
        </div>

        <div className="mt-6">
          <div className="flex items-center gap-3">
            <h2 className="font-semibold text-base mb-2 whitespace-nowrap font-SegoeUI text-[#696A6C]">
              Uploaded Images
            </h2>
            <hr className="flex-grow border-t border-gray-300" />
          </div>
          <div className="flex flex-wrap items-start justify-start gap-4 bg-[#FAFAFA] max-h-96 overflow-y-auto p-4 rounded-md">
            {uploadedImages.map((image, index) => (
              <div key={image.id} className="relative w-40 h-40">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-full rounded-md object-cover"
                />
                {roleId == Role.USER &&
                  userViewDetails.BookDetails.ReviewStatus === Status.REJECTED ? (
                  <button
                    className="cursor-pointer absolute top-1 right-1 bg-customGreen text-white rounded-full w-5 h-5 flex items-center justify-center shadow-md hover:bg-customGreen-dark transition"
                    title="Remove Image"
                    onClick={(event) => handleRemoved(event, image.id)}
                  >
                    <IoCloseSharp size={14} />
                  </button>
                ) : null}
                <></>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default StoryInformationForm;
