import axios from "axios";

const url = process.env.REACT_APP_API_URL + "user";

export const sendOtp = async (payload) => {
  const response = await axios.post(`${url}/send-otp`, payload);
  return response;
};

export const resetPasswordAPI = async (payload) => {
  const response = await axios.put(`${url}/reset-password`, payload);
  return response;
};
