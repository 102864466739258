import React, { useEffect } from "react";
import "../../style/form.css";
import PhotochildFile from "./ChildUploadPhotos/PhotochildFile";
import ActivityCompanionFile from "./ChildUploadPhotos/ActivityCompanionFile";
import ChildHospitalPhotoFile from "./ChildUploadPhotos/ChildHospitalPhotoFile";
import ChallengingActivityFile from "./ChildUploadPhotos/ChallengingActivityFile";
import ChildFriendsFamilyFile from "./ChildUploadPhotos/ChildFriendsFamilyFile";
import ChildMentorHelpingFile from "./ChildUploadPhotos/ChildMentorHelpingFile";
import ChildMentorHelpingSucceedFile from "./ChildUploadPhotos/ChildMentorHelpingSucceedFile";
import ChildFacedChallengeFile from "./ChildUploadPhotos/ChildFacedChallengeFile";
import ChildvictoriousHappyFile from "./ChildUploadPhotos/ChildvictoriousHappyFile";
import ChildSupportiveFamilyFile from "./ChildUploadPhotos/ChildSupportiveFamilyFile";
import ChildSupportiveFamilyTwoFile from "./ChildUploadPhotos/ChildSupportiveFamilyTwoFile";
import ChildphotoMembersTeamFile from "./ChildUploadPhotos/ChildphotoMembersTeamFile";
import ChildOneCloseEyesFile from "./ChildUploadPhotos/ChildOneCloseEyesFile";

function General({
  formData,
  setFormData,
  showUploadError,
  photochildFiles,
  setPhotochildFiles,
  activityCompanionFiles,
  setActivityCompanionFiles,
  childHospitalPhotoFiles,
  setChildHospitalPhotoFiles,
  challengingActivityFiles,
  setChallengingActivityFiles,
  childFriendsFamilyFiles,
  setchildFriendsFamilyFiles,
  childMentorHelpingFiles,
  setChildMentorHelpingFiles,
  childMentorHelpingSucceedFiles,
  setChildMentorHelpingSucceedFiles,
  childFacedChallengeFiles,
  setChildFacedChallengeFiles,
  childvictoriousHappyFiles,
  setChildvictoriousHappyFiles,
  childSupportiveFamilyFiles,
  setChildSupportiveFamilyFiles,
  childSupportiveFamilyTwoFiles,
  setChildSupportiveFamilyTwoFiles,
  childphotoMembersTeamFiles,
  setChildphotoMembersTeamFiles,
  childOneCloseEyesFiles,
  setChildOneCloseEyesFiles,
  userData,
}) {

  useEffect(() => {
    if (userData?.BookDetails?.GeneralInformation) {
      setFormData((prevData) => ({
        ...prevData,
        ...userData.BookDetails.GeneralInformation,
      }));
    }

    if (userData?.BookPhotos) {
      const categorizePhotos = (photoType) =>
        userData.BookPhotos.filter((photo) => photo.PhotoType === photoType).map((photo) => {
          const extension = photo.BlobPath.split(".").pop().toLowerCase();
          const mimeType =
            extension === "png"
              ? "image/png"
              : extension === "jpeg" || extension === "jpg"
                ? "image/jpeg"
                : "application/octet-stream";

          return {
            file: { name: `${photo?.PhotoType}.${photo?.FileExtenstion}`, type: mimeType, size: 0 },
            preview: photo.BlobPath,
          };
        });

      setPhotochildFiles(categorizePhotos("photochildFiles"));
      setActivityCompanionFiles(categorizePhotos("activityCompanionFiles"));
      setChildHospitalPhotoFiles(categorizePhotos("childHospitalPhotoFiles"));
      setChallengingActivityFiles(categorizePhotos("challengingActivityFiles"));
      setchildFriendsFamilyFiles(categorizePhotos("childFriendsFamilyFiles"));
      setChildMentorHelpingFiles(categorizePhotos("childMentorHelpingFiles"));
      setChildMentorHelpingSucceedFiles(categorizePhotos("childMentorHelpingSucceedFiles"));
      setChildFacedChallengeFiles(categorizePhotos("childFacedChallengeFiles"));
      setChildvictoriousHappyFiles(categorizePhotos("childvictoriousHappyFiles"));
      setChildSupportiveFamilyFiles(categorizePhotos("childSupportiveFamilyFiles"));
      setChildSupportiveFamilyTwoFiles(categorizePhotos("childSupportiveFamilyTwoFiles"));
      setChildphotoMembersTeamFiles(categorizePhotos("childphotoMembersTeamFiles"));
      setChildOneCloseEyesFiles(categorizePhotos("childOneCloseEyesFiles"));
    }
  }, [userData]);


  useEffect(() => {
    if (!formData.childFirstNameTwo) {
      setFormData((prevData) => ({
        ...prevData,
        childFirstNameTwo: formData.childFirstName,
      }));
    }

    if (!formData.mentorNameTwo) {
      setFormData((prevData) => ({
        ...prevData,
        mentorNameTwo: formData.mentorName,
      }));
    }

    if (!formData.nameOfMentorTwo) {
      setFormData((prevData) => ({
        ...prevData,
        nameOfMentorTwo: formData.nameOfMentor,
      }));
    }

    if (!formData.initialDiagnosisTwo) {
      setFormData((prevData) => ({
        ...prevData,
        initialDiagnosisTwo: formData.initialDiagnosis,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="first-form-container">
      <div className="input-container-name-conatiner">
        <div>
          <label className="label-text-form" htmlFor="childFirstName">
            What is the child's first name?
          </label>
        </div>
        <div className="input-container-name">
          <input
            type="text"
            id="childFirstName"
            name="childFirstName"
            placeholder="What is the child's first name?"
            value={formData.childFirstName}
            onChange={handleChange}
            maxLength={500}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="storyWriter">
          Who is writing the story?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="storyWriter"
            name="storyWriter"
            maxLength={500}
            placeholder="Who is writing the story?"
            value={formData.storyWriter}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="photochildFiles">
          Photo of child
        </label>
        <div className="input-container-name">
          <PhotochildFile
            photochildFiles={photochildFiles}
            setPhotochildFiles={setPhotochildFiles}
            showError={showUploadError}
            fileKey="photochildFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <div>
          <label className="label-text-form" htmlFor="childFirstNameTwo">
            What is the child's first name?
          </label>
        </div>
        <div className="input-container-name">
          <input
            type="text"
            id="childFirstNameTwo"
            name="childFirstNameTwo"
            placeholder="What is the child's first name?"
            value={formData.childFirstNameTwo}
            maxLength={500}
            readOnly
            onChange={handleChange}
            onFocus={() => {
              setFormData({
                ...formData,
                childFirstNameTwo: formData.childFirstName,
              });
            }}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />

        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="favoriteActivity1">
          What is one activity that the child enjoys?
        </label>
        <div className="input-container-name">
          <textarea
            id="favoriteActivity1"
            name="favoriteActivity1"
            placeholder="What is one activity that the child enjoys?"
            value={formData.favoriteActivity1}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="favoriteActivity2">
          What is another favorite activity?
        </label>
        <div className="input-container-name">
          <textarea
            id="favoriteActivity2"
            name="favoriteActivity2"
            placeholder="What is another favorite activity?"
            value={formData.favoriteActivity2}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="">
          Who is the child with when they participate in this activity?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="activityCompanion"
            name="activityCompanion"
            placeholder="Who is the child with when they participate in this activity?"
            value={formData.activityCompanion}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="favoritePlace">
          What is a favorite place to visit?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="favoritePlace"
            name="favoritePlace"
            placeholder="What is a favorite place to visit?"
            value={formData.favoritePlace}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="activityReason">
          Why is this a favorite activity?
        </label>
        <div className="input-container-name">
          <textarea
            id="activityReason"
            name="activityReason"
            placeholder="Why is this a favorite activity?"
            value={formData.activityReason}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="activityCompanionFiles">
          Photo of child participating in an activity they enjoy (Min 2 Photos)
        </label>
        <div className="input-container-name">
          <ActivityCompanionFile
            activityCompanionFiles={activityCompanionFiles}
            setActivityCompanionFiles={setActivityCompanionFiles}
            showError={showUploadError}
            fileKey="activityCompanionFiles"
          />
        </div>
      </div>

      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="nicknameCaller">
          Who calls the child by a nickname? Family/friends?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="nicknameCaller"
            name="nicknameCaller"
            placeholder="Who calls the child by a nickname? Family/friends?"
            value={formData.nicknameCaller}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="nickname">
          What is the nickname for the child?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="nickname"
            name="nickname"
            placeholder="What is the nickname for the child?"
            value={formData.nickname}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="nicknameReason">
          Why did they choose that nickname?
        </label>
        <div className="input-container-name">
          <textarea
            id="nicknameReason"
            name="nicknameReason"
            placeholder="Why did they choose that nickname?"
            value={formData.nicknameReason}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="birthTiming">
          How many weeks early was the child born or full term?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="birthTiming"
            name="birthTiming"
            placeholder="How many weeks early was the child born or full term?"
            value={formData.birthTiming}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="initialDiagnosis">
          What is the diagnosis that was first discovered?
        </label>
        <div className="input-container-name">
          <textarea
            id="initialDiagnosis"
            name="initialDiagnosis"
            placeholder="What is the diagnosis that was first discovered?"
            value={formData.initialDiagnosis}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="diagnosisDetails">
          You may add details of what was needed as a result of the diagnosis in
          one sentence (from the child's perspective). You may include a second
          sentence to state briefly the challenges this caused for the family.
        </label>
        <div className="input-container-name">
          <textarea
            id="diagnosisDetails"
            name="diagnosisDetails"
            placeholder="You may add details of what was needed as a result of the diagnosis in
   one sentence (from the child's perspective). You may include a second
   sentence to state briefly the challenges this caused for the family."
            value={formData.diagnosisDetails}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childHospitalPhotoFiles">
          Photo of child when diagnosed, could be at hospital
        </label>
        <div className="input-container-name">
          <ChildHospitalPhotoFile
            childHospitalPhotoFiles={childHospitalPhotoFiles}
            setChildHospitalPhotoFiles={setChildHospitalPhotoFiles}
            showError={showUploadError}
            fileKey="activityCompanionFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="challengingActivityFiles">
          Photo of child facing a challenge or engaging in a challenging
          activity
        </label>
        <div className="input-container-name">
          <ChallengingActivityFile
            challengingActivityFiles={challengingActivityFiles}
            setChallengingActivityFiles={setChallengingActivityFiles}
            showError={showUploadError}
            fileKey="challengingActivityFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="additionalDiagnosis">
          What is an additional challenge or diagnosis that was discovered?
        </label>
        <div className="input-container-name">
          <textarea
            id="additionalDiagnosis"
            name="additionalDiagnosis"
            placeholder="What is an additional challenge or diagnosis that was discovered?"
            value={formData.additionalDiagnosis}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="diagnosisDiscovery">
          How did the family or doctors learn about the additional
          challenge/diagnosis?
        </label>
        <div className="input-container-name">
          <textarea
            id="diagnosisDiscovery"
            name="diagnosisDiscovery"
            placeholder="How did the family or doctors learn about the additional
    challenge/diagnosis?"
            value={formData.diagnosisDiscovery}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childFriendsFamilyFiles">
          Photo of child with friends or family looking at or interacting with
          the child
        </label>
        <div className="input-container-name">
          <ChildFriendsFamilyFile
            childFriendsFamilyFiles={childFriendsFamilyFiles}
            setchildFriendsFamilyFiles={setchildFriendsFamilyFiles}
            showError={showUploadError}
            fileKey="childFriendsFamilyFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="initialDiagnosisTwo">
          What is the diagnosis that was first discovered?
        </label>
        <div className="input-container-name">
          <textarea
            id="initialDiagnosisTwo"
            name="initialDiagnosisTwo"
            placeholder="What is the diagnosis that was first discovered?"
            value={formData.initialDiagnosisTwo}
            onChange={handleChange}
            onFocus={() => {
              setFormData({
                ...formData,
                initialDiagnosisTwo: formData.initialDiagnosis,
              });
            }}
            readOnly
            maxLength={500}
            style={{
              width: "608px",
              height: "144px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="diagnosisChallenges">
          What are a few difficulties or challenges faced as a result of the
          diagnosis?
        </label>
        <div className="input-container-name">
          <textarea
            id="diagnosisChallenges"
            name="diagnosisChallenges"
            placeholder="What are a few difficulties or challenges faced as a result of the
     diagnosis?"
            value={formData.diagnosisChallenges}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="familyEmotion">
          What is a feeling the family associates in helping people understand
          the difficulties/diagnosis?
        </label>
        <div className="input-container-name">
          <textarea
            id="familyEmotion"
            name="familyEmotion"
            placeholder=" What is a feeling the family associates in helping people understand
     the difficulties/diagnosis?"
            value={formData.familyEmotion}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="emotionReason">
          Why that emotional response? Because…
        </label>
        <div className="input-container-name">
          <textarea
            id="emotionReason"
            name="emotionReason"
            placeholder="Why that emotional response? Because…"
            value={formData?.emotionReason}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="mentorRelationship">
          What is the relationship of a mentor who has helped most on this
          journey?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="mentorRelationship"
            name="mentorRelationship"
            placeholder="What is the relationship of a mentor who has helped most on this journey?"
            value={formData.mentorRelationship}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="mentorName">
          What is the name of the mentor?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="mentorName"
            name="mentorName"
            placeholder="What is the name of the mentor?"
            value={formData.mentorName}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="mentorAttributes">
          List 1-2 attributes of the mentor.
        </label>
        <div className="input-container-name">
          <textarea
            id="mentorAttributes"
            name="mentorAttributes"
            placeholder="List 1-2 attributes of the mentor."
            value={formData.mentorAttributes}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="mentorNameTwo">
          What is the name of the mentor?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="mentorNameTwo"
            name="mentorNameTwo"
            placeholder="What is the name of the mentor?"
            value={formData.mentorNameTwo}
            readOnly
            maxLength={500}
            onChange={handleChange}
            onFocus={() => {
              setFormData({
                ...formData,
                mentorNameTwo: formData.mentorName,
              });
            }}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childMentorHelpingFiles">
          Photo of child with mentor or someone helping the child with a task
        </label>
        <div className="input-container-name">
          <ChildMentorHelpingFile
            childMentorHelpingFiles={childMentorHelpingFiles}
            setChildMentorHelpingFiles={setChildMentorHelpingFiles}
            showError={showUploadError}
            fileKey="childMentorHelpingFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label
          className="label-text-form"
          htmlFor="childMentorHelpingSucceedFiles"
        >
          Photo of child with someone helping them succeed
        </label>
        <div className="input-container-name">
          <ChildMentorHelpingSucceedFile
            childMentorHelpingSucceedFiles={childMentorHelpingSucceedFiles}
            setChildMentorHelpingSucceedFiles={
              setChildMentorHelpingSucceedFiles
            }
            showError={showUploadError}
            fileKey="childMentorHelpingFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="nameOfMentor">
          Name of mentor.
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="nameOfMentor"
            name="nameOfMentor"
            placeholder="Name of mentor."
            value={formData.nameOfMentor}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="mentorSkills">
          Mention a task/skill (up to 3) the mentor helps the child with.
        </label>
        <div className="input-container-name">
          <textarea
            id="mentorSkills"
            name="mentorSkills"
            placeholder="Mention a task/skill (up to 3) the mentor helps the child with."
            value={formData.mentorSkills}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="RelationShipMentor">
          Relationship of mentor
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="RelationShipMentor"
            name="RelationShipMentor"
            placeholder="Relationship of mentor"
            value={formData.RelationShipMentor}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="ChildsName">
          Child's name
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="ChildsName"
            name="ChildsName"
            placeholder="Child's name"
            value={formData.ChildsName}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childFacedChallengeFiles">
          A photo of the child when faced with a challenge
        </label>
        <div className="input-container-name">
          <ChildFacedChallengeFile
            childFacedChallengeFiles={childFacedChallengeFiles}
            setChildFacedChallengeFiles={setChildFacedChallengeFiles}
            showError={showUploadError}
            fileKey="childFacedChallengeFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="readerHelpChallenge">
          Name a challenge the child faces that the reader can help with.
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="readerHelpChallenge"
            name="readerHelpChallenge"
            placeholder="Name a challenge the child faces that the reader can help with."
            value={formData.readerHelpChallenge}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="readerSupportSuggestion">
          Describe the way the reader can support the child through the
          challenge.
        </label>
        <div className="input-container-name">
          <textarea
            id="readerSupportSuggestion"
            name="readerSupportSuggestion"
            placeholder="Describe the way the reader can support the child through the challenge."
            value={formData.readerSupportSuggestion}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>

      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childvictoriousHappyFiles">
          A photo of the child being victorious or happy
        </label>
        <div className="input-container-name">
          <ChildvictoriousHappyFile
            childvictoriousHappyFiles={childvictoriousHappyFiles}
            setChildvictoriousHappyFiles={setChildvictoriousHappyFiles}
            showError={showUploadError}
            fileKey="childvictoriousHappyFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="nameOfMentorTwo">
          Name of mentor.
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="nameOfMentorTwo"
            name="nameOfMentorTwo"
            placeholder="Name of mentor."
            value={formData.nameOfMentorTwo}
            readOnly
            maxLength={500}
            onChange={handleChange}
            onFocus={() => {
              setFormData({
                ...formData,
                nameOfMentorTwo: formData.nameOfMentor,
              });
            }}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>

      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="misunderstoodReaction">
          What is the child's reaction that is commonly misunderstood?
        </label>
        <div className="input-container-name">
          <textarea
            id="misunderstoodReaction"
            name="misunderstoodReaction"
            placeholder="What is the child's reaction that is commonly misunderstood?"
            value={formData.misunderstoodReaction}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childSupportiveFamilyFiles">
          Another photo of the child with supportive peers or family members
        </label>
        <div className="input-container-name">
          <ChildSupportiveFamilyFile
            childSupportiveFamilyFiles={childSupportiveFamilyFiles}
            setChildSupportiveFamilyFiles={setChildSupportiveFamilyFiles}
            showError={showUploadError}
            fileKey="setChildSupportiveFamilyFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="advocacyHelp">
          How can you suggest others help in being advocates?
        </label>
        <div className="input-container-name">
          <textarea
            id="advocacyHelp"
            name="advocacyHelp"
            placeholder="How can you suggest others help in being advocates?"
            value={formData.advocacyHelp}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label
          className="label-text-form"
          htmlFor="childSupportiveFamilyTwoFiles"
        >
          Another photo of the child with supportive peers or family members
        </label>
        <div className="input-container-name">
          <ChildSupportiveFamilyTwoFile
            childSupportiveFamilyTwoFiles={childSupportiveFamilyTwoFiles}
            setChildSupportiveFamilyTwoFiles={setChildSupportiveFamilyTwoFiles}
            showError={showUploadError}
            fileKey="childSupportiveFamilyTwoFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="supportTeam">
          Who are the people on the child's support team?
        </label>
        <div className="input-container-name">
          <textarea
            id="supportTeam"
            name="supportTeam"
            placeholder="Who are the people on the child's support team?"
            value={formData.supportTeam}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childphotoMembersTeamFiles">
          A family photo or photo with members of the support team
        </label>
        <div className="input-container-name">
          <ChildphotoMembersTeamFile
            childphotoMembersTeamFiles={childphotoMembersTeamFiles}
            setChildphotoMembersTeamFiles={setChildphotoMembersTeamFiles}
            showError={showUploadError}
            fileKey="childphotoMembersTeamFiles"
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="specificDetails">
          Tell the reader about any specific details about the child including
          tools or tips for supporting the child's needs.
        </label>
        <div className="input-container-name">
          <textarea
            id="specificDetails"
            name="specificDetails"
            placeholder="Tell the reader about any specific details about the child including
        tools or tips for supporting the child's needs."
            value={formData.specificDetails}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childsNameTwo">
          What is the child's name?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="childsNameTwo"
            name="childsNameTwo"
            placeholder="What is the child's name?"
            value={formData.childsNameTwo}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childAge">
          What is the child's age?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="childAge"
            name="childAge"
            placeholder="What is the child's age?"
            value={formData.childAge}
            maxLength={500}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="childOneCloseEyesFiles">
          Photo of Child, prefer one close enough to see eyes
        </label>
        <div className="input-container-name">
          <ChildOneCloseEyesFile
            childOneCloseEyesFiles={childOneCloseEyesFiles}
            setChildOneCloseEyesFiles={setChildOneCloseEyesFiles}
            showError={showUploadError}
            fileKey="childOneCloseEyesFiles"
          />
        </div>
      </div>
    </div>
  );
}

export default General;
