import React, { useContext, useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import General from "./General";
import SuccessMessage from "./SuccessMessage";
import Footer from "../../components/nav-bar/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import { useToast } from "../../ToastContext";
import { handleFormSubmitted } from "../../api/book";
import { useNavigation } from "../../components/NavigationContext";
import { UserFormContext } from "../../components/UserFormContext";

function FormValid({ userData }) {
  const dashboardRef = useRef(null);
  const toast = useToast();
  const [activeSection, setActiveSection] = useState("section1");

  const {
    formData,
    setFormData,
    loading,
    setLoading,
    showUploadError,
    photochildFiles,
    setPhotochildFiles,
    activityCompanionFiles,
    setActivityCompanionFiles,
    childHospitalPhotoFiles,
    setChildHospitalPhotoFiles,
    challengingActivityFiles,
    setChallengingActivityFiles,
    childFriendsFamilyFiles,
    setchildFriendsFamilyFiles,
    childMentorHelpingFiles,
    setChildMentorHelpingFiles,
    childMentorHelpingSucceedFiles,
    setChildMentorHelpingSucceedFiles,
    childFacedChallengeFiles,
    setChildFacedChallengeFiles,
    childvictoriousHappyFiles,
    setChildvictoriousHappyFiles,
    childSupportiveFamilyFiles,
    setChildSupportiveFamilyFiles,
    childSupportiveFamilyTwoFiles,
    setChildSupportiveFamilyTwoFiles,
    childphotoMembersTeamFiles,
    setChildphotoMembersTeamFiles,
    childOneCloseEyesFiles,
    setChildOneCloseEyesFiles,
  } = useContext(UserFormContext);
  const { setIsFormDirty } = useNavigation();
  const userId = Number(localStorage.getItem("userId"));

  const isFirstRender = useRef(true);

  const renderSectionContent = () => {
    switch (activeSection) {
      case "section1":
        return (
          <General
            userData={userData}
            activityCompanionFiles={activityCompanionFiles}
            setActivityCompanionFiles={setActivityCompanionFiles}
            showUploadError={showUploadError}
            formData={formData}
            photochildFiles={photochildFiles}
            setPhotochildFiles={setPhotochildFiles}
            childHospitalPhotoFiles={childHospitalPhotoFiles}
            setChildHospitalPhotoFiles={setChildHospitalPhotoFiles}
            challengingActivityFiles={challengingActivityFiles}
            setChallengingActivityFiles={setChallengingActivityFiles}
            childFriendsFamilyFiles={childFriendsFamilyFiles}
            setchildFriendsFamilyFiles={setchildFriendsFamilyFiles}
            childMentorHelpingFiles={childMentorHelpingFiles}
            setChildMentorHelpingFiles={setChildMentorHelpingFiles}
            childMentorHelpingSucceedFiles={childMentorHelpingSucceedFiles}
            setChildMentorHelpingSucceedFiles={
              setChildMentorHelpingSucceedFiles
            }
            childFacedChallengeFiles={childFacedChallengeFiles}
            setChildFacedChallengeFiles={setChildFacedChallengeFiles}
            childvictoriousHappyFiles={childvictoriousHappyFiles}
            setChildvictoriousHappyFiles={setChildvictoriousHappyFiles}
            childSupportiveFamilyFiles={childSupportiveFamilyFiles}
            setChildSupportiveFamilyFiles={setChildSupportiveFamilyFiles}
            childSupportiveFamilyTwoFiles={childSupportiveFamilyTwoFiles}
            setChildSupportiveFamilyTwoFiles={setChildSupportiveFamilyTwoFiles}
            childphotoMembersTeamFiles={childphotoMembersTeamFiles}
            setChildphotoMembersTeamFiles={setChildphotoMembersTeamFiles}
            childOneCloseEyesFiles={childOneCloseEyesFiles}
            setChildOneCloseEyesFiles={setChildOneCloseEyesFiles}
            setFormData={setFormData}
          />
        );
      case "section4":
        return <SuccessMessage />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (dashboardRef.current) {
      dashboardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const dirty = Object.values(formData).some((value) => value !== "");
    setIsFormDirty(dirty);
  }, [formData, setIsFormDirty]);

  const handleContinueClick = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      const isEdit = userData?.BookDetails?.Id ? true : false;
      const bookId = isEdit ? userData.BookDetails.Id : null;
      formDataToSend.append("name", `${formData.childFirstName} Story`);
      formDataToSend.append("userId", Number(userId));
      formDataToSend.append("isDraft", false);
      formDataToSend.append("isEdit", isEdit);
      if (isEdit) {
        formDataToSend.append("bookId", bookId);
      }

      const jsonData = {
        childFirstName: formData.childFirstName,
        storyWriter: formData.storyWriter,
        photochildFiles: "",
        childFirstNameTwo: formData.childFirstNameTwo,
        favoriteActivity1: formData.favoriteActivity1,
        favoriteActivity2: formData.favoriteActivity2,
        activityCompanion: formData.activityCompanion,
        favoritePlace: formData.favoritePlace,
        activityReason: formData.activityReason,
        activityCompanionFiles: "",
        nicknameCaller: formData.nicknameCaller,
        nickname: formData.nickname,
        nicknameReason: formData.nicknameReason,
        birthTiming: formData.birthTiming,
        initialDiagnosis: formData.initialDiagnosis,
        diagnosisDetails: formData.diagnosisDetails,
        childHospitalPhotoFiles: "",
        challengingActivityFiles: "",
        additionalDiagnosis: formData.additionalDiagnosis,
        diagnosisDiscovery: formData.diagnosisDiscovery,
        childFriendsFamilyFiles: "",
        initialDiagnosisTwo: formData.initialDiagnosisTwo,
        diagnosisChallenges: formData.diagnosisChallenges,
        familyEmotion: formData.familyEmotion,
        emotionReason: formData.emotionReason,
        mentorRelationship: formData.mentorRelationship,
        mentorName: formData.mentorName,
        mentorAttributes: formData.mentorAttributes,
        mentorNameTwo: formData.mentorNameTwo,
        childMentorHelpingFiles: "",
        childMentorHelpingSucceedFiles: "",
        nameOfMentor: formData.nameOfMentor,
        mentorSkills: formData.mentorSkills,
        RelationShipMentor: formData.RelationShipMentor,
        ChildsName: formData.ChildsName,
        childFacedChallengeFiles: "",
        readerHelpChallenge: formData.readerHelpChallenge,
        readerSupportSuggestion: formData.readerSupportSuggestion,
        childvictoriousHappyFiles: "",
        nameOfMentorTwo: formData.nameOfMentorTwo,
        misunderstoodReaction: formData.misunderstoodReaction,
        childSupportiveFamilyFiles: "",
        advocacyHelp: formData.advocacyHelp,
        childSupportiveFamilyTwoFiles: "",
        supportTeam: formData.supportTeam,
        childphotoMembersTeamFiles: "",
        specificDetails: formData.specificDetails,
        childsNameTwo: formData.childsNameTwo,
        childAge: formData.childAge,
        childOneCloseEyesFiles: "",
      };
      formDataToSend.append("generalInformation", JSON.stringify(jsonData));
      const appendFiles = (files, key) => {
        files.forEach((fileObj) => {
          formDataToSend.append(key, fileObj.file);
        });
      };

      appendFiles(photochildFiles, "photochildFiles");
      appendFiles(activityCompanionFiles, "activityCompanionFiles");
      appendFiles(childHospitalPhotoFiles, "childHospitalPhotoFiles");
      appendFiles(challengingActivityFiles, "challengingActivityFiles");
      appendFiles(childFriendsFamilyFiles, "childFriendsFamilyFiles");
      appendFiles(childMentorHelpingFiles, "childMentorHelpingFiles");
      appendFiles(childMentorHelpingSucceedFiles, "childMentorHelpingSucceedFiles");
      appendFiles(childFacedChallengeFiles, "childFacedChallengeFiles");
      appendFiles(childvictoriousHappyFiles, "childvictoriousHappyFiles");
      appendFiles(childSupportiveFamilyFiles, "childSupportiveFamilyFiles");
      appendFiles(childSupportiveFamilyTwoFiles, "childSupportiveFamilyTwoFiles");
      appendFiles(childphotoMembersTeamFiles, "childphotoMembersTeamFiles");
      appendFiles(childOneCloseEyesFiles, "childOneCloseEyesFiles");

      setLoading(true);

      const response = await handleFormSubmitted(formDataToSend);

      if (response?.status === 200 && response.data) {
        setPhotochildFiles([]);
        setActivityCompanionFiles([]);
        setChildHospitalPhotoFiles([]);
        setChallengingActivityFiles([]);
        setchildFriendsFamilyFiles([]);
        setChildMentorHelpingFiles([]);
        setChildMentorHelpingSucceedFiles([]);
        setChildFacedChallengeFiles([]);
        setChildvictoriousHappyFiles([]);
        setChildSupportiveFamilyFiles([]);
        setChildSupportiveFamilyTwoFiles([]);
        setChildphotoMembersTeamFiles([]);
        setChildOneCloseEyesFiles([]);
        setFormData({});
        setActiveSection("section4");
        localStorage.removeItem("userData");
        setIsFormDirty(false)
      } else {
        setActiveSection("section1");
        toast.error("Error during submission. Please try again.");
      }
    } catch (error) {
      setActiveSection("section1");
      console.error("Submission error:", error);
      toast.error("Error during submission. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClearClick = () => {
    setPhotochildFiles([]);
    setActivityCompanionFiles([]);
    setChildHospitalPhotoFiles([]);
    setChallengingActivityFiles([]);
    setchildFriendsFamilyFiles([]);
    setChildMentorHelpingFiles([]);
    setChildMentorHelpingSucceedFiles([]);
    setChildFacedChallengeFiles([]);
    setChildvictoriousHappyFiles([]);
    setChildSupportiveFamilyFiles([]);
    setChildSupportiveFamilyTwoFiles([]);
    setChildphotoMembersTeamFiles([]);
    setChildOneCloseEyesFiles([]);
    setFormData({});
    setActiveSection("section1");
    localStorage.removeItem("userData");
  };

  // const isSubmitDisabled = () => {
  //   return activeSection === "section1"
  //     ? photochildFiles.length === 0 ||
  //     activityCompanionFiles.length === 0 ||
  //     childHospitalPhotoFiles.length === 0 ||
  //     challengingActivityFiles.length === 0 ||
  //     childFriendsFamilyFiles.length === 0 ||
  //     childMentorHelpingFiles.length === 0 ||
  //     childMentorHelpingSucceedFiles.length === 0 ||
  //     childFacedChallengeFiles.length === 0 ||
  //     childvictoriousHappyFiles.length === 0 ||
  //     childSupportiveFamilyFiles.length === 0 ||
  //     childSupportiveFamilyTwoFiles.length === 0 ||
  //     childphotoMembersTeamFiles.length === 0 ||
  //     childOneCloseEyesFiles.length === 0 ||
  //     loading
  //     : false;
  // };

  return (
    <div className="form-page" ref={dashboardRef}>
      <div className="form-first-pages">
        <div className="text-container-form">
          <span className="form-heading-text">
            Enroll in the Journey Book Story
            <br />
            Crafting Academy Today
          </span>
        </div>
      </div>
      <div className="home-heading-line-container p-6 text-center w-full">
        {activeSection !== "section4" && (
          <div className="bg-gray-100 border border-[#E1E6E6] w-[100%] p-2 text-center flex items-center justify-around flex-col rounded-lg">
            <span className="text-black font-JosefinSans font-normal text-2xl block w-[80%]">
              Your answers help us create the best Journey Book™
              for your child. If you're unsure about how to answer a question, feel free to skip it for now. We’ll review your answers and guide you through the rest.
            </span>
            <hr className="border-gray-300 my-4 w-[80%] mx-auto" />
            <span className="text-black font-JosefinSans font-normal text-2xl block w-[80%]">
              If you need additional assistance,
              <a href="contactUs" className="text-teal-600 border-b-2 border-teal-600"> Contact Us </a>
              and a personal guide will reach out to get started.
            </span>
          </div>
        )}
      </div>
      {
        loading ? (
          <div
            style={{
              width: "100%",
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={24} color="success" />
          </div>
        ) : (
          <div className="form-valid-container">
            <div className="form-btn-box-container">
              <div className="form-body-content">{renderSectionContent()}</div>
              {/* {activeSection !== "section4" &&
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                  }}>
                  <button
                    className="custom-button-form-btn"
                    onClick={() => handleNavigation("/userdashboard")}
                    // disabled={isSubmitDisabled()}
                    style={{
                      // backgroundColor: isSubmitDisabled() ? "#d3d3d3" : "#008080",
                      // cursor: isSubmitDisabled() ? "not-allowed" : "pointer",
                    }}
                  >
                    Draft
                  </button></div>} */}
              {activeSection !== "section4" && (
                <div className="form-button-content">
                  <button
                    className="custom-clear-btn-form"
                    onClick={handleClearClick}
                  >
                    Clear
                  </button>
                  <button
                    className="custom-button-form-btn"
                    onClick={handleContinueClick}
                    // disabled={isSubmitDisabled()}
                    style={{
                      backgroundColor: "#008080",
                      cursor: "pointer",
                    }}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        )
      }
      <div style={{ marginTop: "30px" }}>
        <Footer />
      </div>
      {/* {draftModalIsOpen && (
        <SaveDraft
          isOpen={draftModalIsOpen}
          onClose={handleCancel}
          onSaveDraft={handleSaveDraft}
        />
      )} */}
    </div >
  );
}

export default FormValid;
