import React, { useState, useEffect, useContext } from "react";
import "../../style/headNav.css";
import JourneyBooks from "../../assets/images/Journey_Books.png-removebg-preview.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Menus } from "./menu";
import { IconButton, MenuItem, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { HiOutlineUser } from "react-icons/hi";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { TbLogout2 } from "react-icons/tb";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReactModal from "react-modal";
import LogoutModal from "./LogoutModal";
import { useToast } from "../../ToastContext";
import { FormContext } from "../FormContext";
import * as Role from "../../Entities/Role";
import { useNavigation } from "../NavigationContext";

ReactModal.setAppElement("#root");

function HeadNav() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const [navTitle, setNavTitle] = useState(path[path.length - 1]);
  const [open, setOpen] = useState(false);
  const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
  const avatarAnchorRef = React.useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();
  const [logoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const toast = useToast();
  const { formData } = useContext(FormContext);
  const [roleChange, setRoleChange] = useState(null);
  const roleId = localStorage.getItem("RoleId");
  const { handleNavigation } = useNavigation();

  const roles = {
    admin: "Admin",
    reviewer: "Reviewer",
    user: "",
  };
  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleAvatarMenuToggle = () => setAvatarMenuOpen(!avatarMenuOpen);
  const handleAvatarMenuClose = () => setAvatarMenuOpen(false);
  const token = localStorage.getItem("accessToken");
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickNav = (targetPage) => {
    handleNavigation(targetPage)
    setNavTitle(targetPage);
    setOpen(false);
  };

  useEffect(() => {
    setNavTitle(path[path.length - 1]);
  }, [path]);


  const handleLogoutModel = () => {
    handleAvatarMenuClose();
    setLogoutPopupOpen(true)
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("RoleId");
    localStorage.setItem("IsRememberMe", JSON.stringify(false));
    setIsLoggedIn(false);
    setAvatarMenuOpen(false);
    setLogoutPopupOpen(false);
    window.location.href = "/home";
  };

  const handleCancelLogout = () => {
    setLogoutPopupOpen(false);
  };

  const handleDashboard = () => {
    const parsedRoleId = parseInt(roleId, 10);
    switch (parsedRoleId) {
      case Role.ADMIN:
        handleNavigation("admindashboard");
        navigate("/admindashboard");
        break;
      case Role.REVIEWER:
        handleNavigation("reviewer");
        navigate("/reviewer");
        break;
      case Role.USER:
        handleNavigation("userdashboard");
        navigate("/userdashboard");
        break;
      default:
        console.error("Invalid RoleId");
        break;
    }

    handleAvatarMenuClose(); // Close the dropdown menu after navigation
  };

  const handleprofile = () => {
    navigate("/profile");
    handleAvatarMenuClose();
  };

  const handleDemoPDFClick = () => {
    window.open(
      "https://flipbooks.journeybookstories.com/index.html",
      "_blank"
    );
  };

  useEffect(() => {
    try {
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      toast.error("Network error. Please try again later.", error);
    }
  }, []);

  useEffect(() => {
    const roleId = localStorage.getItem("RoleId");
    if (roleId) {
      const parsedRoleId = parseInt(roleId, 10);
      let roleName = "";
      switch (parsedRoleId) {
        case Role.ADMIN:
          roleName = roles.admin;
          break;
        case Role.REVIEWER:
          roleName = roles.reviewer;
          break;
        case Role.USER:
          roleName = roles.user;
          break;
        default:
          console.error("Invalid RoleId");
          break;
      }
      setRoleChange(roleName);
    }
  }, []);

  return (
    <div className="header-main">
      <div className="jeurney-logo">
        <Link to="home">
          <img
            src={JourneyBooks}
            onClick={() => setNavTitle("home")}
            className="logo-header-img"
            alt="JourneyBooks"
          />
        </Link>
      </div>
      <div className="top-navbar">
        <nav className="nav-menu">
          <div className="nav-bar">
            <div className="nav-menu-item">
              {Menus?.map((item, index) => (
                <div key={index} className={item.cName}>
                  <NavLink to={item.path} className="link">
                    <div
                      className={`title-name active-nav ${navTitle === item.path ? "clicked-nav" : ""
                        }`}
                      onClick={() => handleClickNav(item.path)}
                    >
                      {item.title}
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
            <div className="dtds-navbar-toggle">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MenuIcon fontSize="medium" />
              </IconButton>
              <Menu
                onClose={handleClose}
                id="account-menu"
                open={open}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 8.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 2,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                {Menus?.map((item, index) => (
                  <MenuItem key={index} onClick={handleClose}>
                    <NavLink
                      to={item.path}
                      className="link"
                      onClick={handleClick}
                    >
                      {item.title}
                    </NavLink>
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="relative align-middle justify-around w-auto">
              <div className="flex flex-row align-middle justify-around sm:mb-2">
                <div className="border-l-2 border-l-gray-200 pb-2 mr-3"></div>
                <div className="mt-2 flex items-start justify-start flex-col">
                  <p className="text-[#008080] text-lg font-medium font-Roboto relative top-1">
                    {isLoggedIn
                      ? `${formData?.firstName
                        ?.charAt(0)
                        .toUpperCase()}${formData?.firstName?.slice(1)}`
                      : ""}
                  </p>
                  <span className="text-[#707373] text-sm font-normal font-Roboto relative bottom-1">
                    {isLoggedIn ? roleChange || "" : ""}
                  </span>
                </div>
                <div className="ml-3">
                  {isLoggedIn ? (
                    formData?.UserProfilePhoto !== "" ? (
                      <img
                        src={formData?.UserProfilePhoto}
                        ref={avatarAnchorRef}
                        onClick={handleAvatarMenuToggle}
                        alt="Avatar"
                        className="w-12 h-12 relative top-1 rounded-full object-cover border-2 border-blue-400 cursor-pointer"
                      />
                    ) : (
                      <IconButton
                        ref={avatarAnchorRef}
                        onClick={handleAvatarMenuToggle}
                        aria-label="avatar menu"
                        className="relative top-1"
                      >
                        <AccountCircleIcon
                          className="text-gray-800"
                          fontSize="large"
                        />
                      </IconButton>
                    )
                  ) : (
                    <div className="flex space-x-4">
                      <button
                        onClick={handleDemoPDFClick}
                        className="bg-teal-600 cursor-pointer font-Poppins text-white text-sm px-2 py-2 rounded hover:bg-teal-700"
                      >
                        Sample Book
                      </button>
                      <div className="border-l-2 border-l-gray-200 pb-2 mr-3"></div>
                      <NavLink
                        to="/login"
                        className="text-teal-600 cursor-pointer font-Poppins text-base font-medium hover:underline pt-1"
                      >
                        Sign In
                      </NavLink>
                    </div>
                  )}
                  <Menu
                    anchorEl={avatarAnchorRef.current}
                    open={avatarMenuOpen}
                    onClose={handleAvatarMenuClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <MenuItem
                      onClick={handleprofile}
                      className="flex items-center justify-between space-x-4 hover:bg-gray-100"
                    >
                      <div className="w-full border-b-2 border-b-gray-200 pb-2 flex items-center space-x-4">
                        <HiOutlineUser color="#242634" size={20} />
                        <span className="text-gray-800 text-sm font-medium font-inter">
                          Profile
                        </span>
                      </div>
                    </MenuItem>
                    <MenuItem
                      onClick={handleDashboard}
                      className="flex items-center justify-between space-x-4 hover:bg-gray-100"
                    >
                      <div className="w-full border-b-2 border-b-gray-200 pb-2 flex items-center space-x-4">
                        <HiOutlineComputerDesktop color="#242634" size={20} />
                        <span className="text-gray-800 text-sm font-medium font-inter">
                          Dashboard
                        </span>
                      </div>
                    </MenuItem>
                    <MenuItem
                      onClick={handleLogoutModel}
                      className="flex items-center justify-between space-x-4 hover:bg-gray-100"
                    >
                      <div className="w-full border-b-2 border-b-gray-200 pb-2 flex items-center space-x-4">
                        <TbLogout2 color="#242634" size={20} />
                        <span className="text-gray-800 text-sm font-medium font-inter">
                          Logout
                        </span>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <LogoutModal
        isOpen={logoutPopupOpen}
        onClose={handleCancelLogout}
        onLogout={handleLogout}
      />
    </div>
  );
}

export default HeadNav;
