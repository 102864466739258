import { useEffect } from "react";
import * as Role from "../../Entities/Role";

function ApproveSection({
  status,
  setStatus,
  reason,
  setReason,
  roleId,
  userViewDetails,
}) {
  useEffect(() => {
    setStatus("");
    setReason("");
  }, []);
  return (
    <div className="mb-4">
      {(roleId == Role.ADMIN || roleId == Role.REVIEWER) && (
        <>
          <div className="flex items-center gap-3">
            <h2 className="font-semibold text-base mb-2 whitespace-nowrap font-SegoeUI text-[#696A6C]">
              {(roleId == Role.ADMIN || status == "approved") && "Approved"}
              {(roleId == Role.REVIEWER || status == "reviewed") &&
                "Reviewed"}{" "}
              Section
            </h2>
            <hr className="flex-grow border-t border-gray-300" />
          </div>
          <div className="bg-[#FCFCFD] p-4 rounded-lg border border-gray-200">
            <select
              className="w-full text-sm text-gray-600 bg-transparent font-normal font-roboto border-none outline-none cursor-pointer"
              value={status || ""}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="" disabled>
                --Select--{" "}
              </option>
              {(roleId == Role.ADMIN || status == "approved") && (
                <option value="approved">Approved</option>
              )}
              {(roleId == Role.REVIEWER || status == "reviewed") && (
                <option value="reviewed">Reviewed</option>
              )}
              <option value="rejected">Rejected</option>
            </select>
          </div>
        </>
      )}
      {status == "rejected" && (
        <div className="mt-4">
          <label
            htmlFor="reason"
            className="block text-sm font-medium text-gray-700 mb-2 font-poppins"
          >
            Reason:
          </label>
          <input
            type="text"
            id="reason"
            placeholder="Reason"
            className="w-full border border-gray-300 rounded-lg py-6 px-4 text-sm text-gray-600"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      )}
      {userViewDetails?.BookDetails?.Reason
        ? roleId === Role.USER && (
            <div className="mt-4">
              <label
                htmlFor="reason"
                className="block text-sm font-medium text-gray-700 mb-2 font-poppins"
              >
                Reason:
              </label>
              <input
                type="text"
                id="reason"
                placeholder="Reason"
                className="w-full border border-gray-300 rounded-lg py-6 px-4 text-sm text-gray-800"
                value={reason || userViewDetails?.BookDetails?.Reason || ""}
                disabled
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          )
        : null}
    </div>
  );
}

export default ApproveSection;
