import React, { useEffect, useRef } from 'react';
import '../../style/testimonials.css';
import Footer from '../../components/nav-bar/Footer';
import { FaStar } from 'react-icons/fa';

function Testimonials() {
     const dashboardRef = useRef(null);
      
      useEffect(() => {
        if (dashboardRef.current) {
          dashboardRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, []);
      
  return (
    <div className='testimonials-page' ref={dashboardRef}>
      <div className="testimonials-container-page">
        <div className="testimonials-first-pages">
          <div className="testimonials-heading">
            <span className='testimonialsPage-heading-text'>What Our Readers Are Saying</span>
          </div>
          <div className="testimonials-cards">
            <div className="testimonials-sub-cards">
              <div className="testimonial">
                <p className="quote">
                  <FaStar color="#FFD700" /> "A Magical Experience!"
                </p>
                <p>Creating a personalized storybook for my daughter was a magical experience. Seeing her face light up as she read her own name in the story was priceless. Thank you, Journey Book Stories!</p>
                <p className="author">
                  - <span className="author-highlight">Sarah L.</span>, Proud Mom
                </p>
              </div>
              <div className="testimonial">
                <p className="quote">
                  <FaStar color="#FFD700" /> "An Unforgettable Gift"
                </p>
                <p>I gave my nephew a customized adventure book for his birthday, and it was the best gift he's ever received. He couldn't stop reading it and showing it to all his friends.</p>
                <p className="author">
                  - <span className="author-highlight">James M.</span>, Happy Uncle
                </p>
              </div>
              <div className="testimonial">
                <p className="quote">
                  <FaStar color="#FFD700" /> "A Touch of Personalization"
                </p>
                <p>The process of personalizing the story was so easy and fun. The quality of the printed book exceeded my expectations. Journey Book Stories helped me create a keepsake that our family will cherish forever.</p>
                <p className="author">
                  - <span className="author-highlight">Emily R.</span>, Delighted Customer
                </p>
              </div>
              <div className="testimonial">
                <p className="quote">
                  <FaStar color="#FFD700" /> "Perfect for Bedtime Stories"
                </p>
                <p>My kids love bedtime even more now that they have their own personalized storybooks. Each night, they eagerly wait to hear their names in the adventures. It's been a wonderful addition to our nightly routine.</p>
                <p className="author">
                  - <span className="author-highlight">Michael T.</span>, Grateful Dad
                </p>
              </div>
              <div className="testimonial">
                <p className="quote">
                  <FaStar color="#FFD700" /> "Impressive Quality and Service"
                </p>
                <p>From start to finish, Journey Book Stories provided exceptional service. The website was easy to navigate, the customization options were plentiful, and the final product was beautiful. Highly recommended!</p>
                <p className="author">
                  - <span className="author-highlight">Laura K.</span>, Satisfied Customer
                </p>
              </div>
              <div className="testimonial">
                <p className="quote">
                  <FaStar color="#FFD700" /> "A Unique and Special Gift"
                </p>
                <p>I created a personalized storybook for my friend’s baby shower, and it was a hit! Everyone loved the idea, and it made the event even more special. I'll definitely be back for more.</p>
                <p className="author">
                  - <span className="author-highlight">Rebecca S.</span>, Thrilled Friend
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '50px', width: '100%' }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
