import React from 'react'
import "../../style/main.css";
import "../../style/form.css";
import FormValid from './FormValid';
import { useLocation } from 'react-router-dom';

function Form() {
	const location = useLocation();
	const userData = location.state?.userData;
	return (
		<div className='form-main-container'>
			<div className="body-component-form">
				<FormValid userData={userData} />
			</div>
		</div>
	)
}

export default Form