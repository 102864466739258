import axios from "axios";
const url = process.env.REACT_APP_API_URL + "user/login";

export const loginAPI = async (payload) => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  const response = await axios.post(url, payload, {
    headers: {
      clientid: clientId,
      clientsecret: clientSecret,
    },
  });

  return response;
};
