import React, { useState } from "react";
import ReactModal from "react-modal";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import { VscClose } from "react-icons/vsc";
import { BsCheck2 } from "react-icons/bs";
import { Drawer } from "@mui/material";
import sideImg from "../../assets/images/sidebar-left.png";
import ConfirmModal from "./ConfirmModal";
import ConfirmationMessage from "./ConfirmationMessage";
import StoryInformationForm from "./StoryInformationForm";
import ApproveSection from "./ApproveSection";
import DrawerHeader from "./DrawerHeader";
import ShippingBookInfo from "./ShippingBookInfo";
import { useToast } from "../../ToastContext";
import { UpdateReviewAPI } from "../../api/book";
import * as Status from "../../Entities/RoleStatus";
import * as Role from "../../Entities/Role";

ReactModal.setAppElement("#root");

function ApproveOrdersModal({
  isOpen,
  closeModal,
  userViewDetails,
  fetchUserDetails,
}) {
  const toast = useToast();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [reason, setReason] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const roleId = localStorage.getItem("RoleId");
  const parsedRoleId = parseInt(roleId, 10);
  const userId = localStorage.getItem("userId");
  const [status, setStatus] = useState("");

  const getStatusCode = (status) => {
    switch (status) {
      case "approved":
        return Status.APPROVED;
      case "rejected":
        return Status.REJECTED;
      case "reviewed":
        return Status.REVIEWE;
      default:
        return 0;
    }
  };

  const handleSubmit = async () => {
    if (status === "rejected" && !reason.trim()) {
      toast.error("Please provide a reason for rejection.");
      return;
    }
    const bookId = userViewDetails?.BookDetails?.Id;
    const payload = {
      userId: userId,
      bookId: bookId,
      reviewStatus: getStatusCode(status),
      reason: reason || null,
    };

    try {
      const response = await UpdateReviewAPI(payload);
      if (response && response.data && response.status === 200) {
        fetchUserDetails();
        setShowConfirmModal(true);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating" || error);
    }
  };

  const confirmAction = () => {
    setShowConfirmModal(false);
    setShowConfirmationMessage(true);
    setTimeout(() => {
      setShowConfirmationMessage(false);
    }, 2000);
    closeModal();
    setReason("");
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeModal}
        sx={{
          "& .MuiDrawer-paper": {
            width: {
              xs: "100%",
              sm: "600px",
              md: "800px",
            },
          },
        }}
      >
        <DrawerHeader
          closeModal={closeModal}
          setStatus={setStatus}
          userViewDetails={userViewDetails}
          sideImg={sideImg}
        />
        <div className="p-3 mt-20">
          <div className="flex items-center space-x-4 mb-6">
            <div className="w-14 h-14 bg-gray-300 rounded-full flex justify-center items-center">
              <div className="w-12 h-12 rounded-full bg-gray-500 flex items-center justify-center overflow-hidden">
                {userViewDetails?.UserProfile?.ProfilePhotoUrl ? (
                  <img
                    src={userViewDetails.UserProfile.ProfilePhotoUrl}
                    alt="User Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <span className="text-white text-lg font-bold">
                    {userViewDetails?.UserDetails?.FirstName?.[0]?.toUpperCase() ||
                      "U"}
                  </span>
                )}
              </div>
            </div>
            <div>
              <h2 className="text-lg font-semibold font-Poppins text-black">
                {userViewDetails?.UserDetails?.FirstName}{" "}
                {userViewDetails?.UserDetails?.LastName}
              </h2>
              <div className="flex flex-col sm:flex-row sm:space-x-4 text-gray-600 text-sm mt-1">
                <div className="flex items-center mt-1">
                  <BsTelephone size={16} className="mr-2" />
                  <span className="font-medium font-poppins text-sm text-customTextGrey">
                    {userViewDetails?.UserProfile?.PhoneNumber}
                  </span>
                </div>
                <div className="flex items-center mt-1">
                  <MdOutlineMailOutline size={16} className="mr-2" />
                  <span className="font-medium font-poppins text-sm text-customTextGrey">
                    {userViewDetails?.UserDetails?.EmailId}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <ShippingBookInfo userViewDetails={userViewDetails} />
          <div className="mb-4">
            <div className="flex items-center gap-3">
              <h2 className="font-semibold text-base mb-2 whitespace-nowrap font-SegoeUI text-[#696A6C]">
                Story Information
              </h2>
              <hr className="flex-grow border-t border-gray-300" />
            </div>
            <StoryInformationForm
              roleId={roleId}
              uploadedImages={uploadedImages}
              userViewDetails={userViewDetails}
              setUploadedImages={setUploadedImages}
            />
          </div>
          <ApproveSection
            userViewDetails={userViewDetails}
            status={status}
            roleId={parsedRoleId}
            setStatus={setStatus}
            reason={reason}
            setReason={setReason}
            handleSubmit={handleSubmit}
          />
        </div>
        {(roleId == Role.ADMIN || roleId == Role.REVIEWER) && (
          <div className="flex justify-end items-end gap-8 bg-white p-4 rounded-md shadow-md border-t border-t-gray-200 ">
            <button
              onClick={closeModal}
              className="font-normal cursor-pointer text-sm px-4 py-2 border-gray-300 border-2 text-gray-700 rounded hover:bg-gray-300 flex items-center w-full sm:w-auto justify-center"
            >
              <VscClose size={16} className="mr-3" />
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="font-normal cursor-pointer text-sm px-4 py-2 bg-customGreen text-white rounded hover:bg-[#006666] transition duration-200 flex items-center w-full sm:w-auto justify-center"
            >
              <BsCheck2 size={16} className="mr-3" />
              Submit
            </button>
          </div>
        )}
      </Drawer>
      <ConfirmModal
        show={showConfirmModal}
        status={status}
        onConfirm={confirmAction}
        onClose={() => setShowConfirmModal(false)}
      />
      <ConfirmationMessage
        show={showConfirmationMessage}
        onConfirm={confirmAction}
        status={status}
        onClose={() => setShowConfirmModal(false)}
      />
    </>
  );
}

export default ApproveOrdersModal;
