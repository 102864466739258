import instance from "../axiosConfig";

const URL = process.env.REACT_APP_API_URL + "book";
const endPoint = process.env.REACT_APP_API_URL ;

export const handleFormSubmitted = (payload) => {
  let response = instance.post(`${URL}/create`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const UpdateReviewAPI = (payload) => {
  let response = instance.put(`${endPoint}book/review`, payload);
  return response;
};

export const RemoveUploadImgAPI = (payload) => {
  let response = instance.delete(`${URL}/remove-photos`, { data: payload });
  return response;
};
