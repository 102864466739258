import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('accessToken');
    const location = useLocation();

    if (!token) {
        localStorage.setItem('redirectAfterLogin', location.pathname);
        return <Navigate to="/login" replace />;
    }
    return children;
};

export default ProtectedRoute;
