import React from "react";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import { AiOutlineEdit } from "react-icons/ai";

function ActiveOrder({ data, getStatusStyle, handleModelOpen }) {
	return (
		<div className="w-full flex items-center justify-center">
			<div className="w-11/12 sm:w-10/12 md:w-9/12 lg:w-11/12 flex items-center justify-center mt-10">
				<div className="border border-gray-300 rounded-lg shadow-sm w-full">
					<h2 className="text-xl font-semibold text-gray-700 p-2 ml-1">
						Active Orders
					</h2>
					<div className="overflow-y-auto max-h-80">
						<table className="min-w-full shadow-md rounded-lg border border-gray-200">
							<thead className="bg-white sticky top-0 z-10 border-b border-gray-300 shadow-sm">
								<tr>
									<th className="py-3 sm:text-sm text-xs px-4 sm:px-6 text-left font-semibold text-gray-500 bg-white">
										Book ID
									</th>
									<th className="py-3 sm:text-sm text-xs px-4 text-left font-semibold text-gray-500 bg-white">
										Book Title
									</th>
									<th className="py-3 sm:text-sm text-xs px-4 text-left font-semibold text-gray-500 bg-white">
										Order Date
									</th>
									<th className="py-3 sm:text-sm text-xs px-10 text-left font-semibold text-gray-500 bg-white">
										Status
									</th>
									<th className="py-3 sm:text-sm text-xs px-3 text-left font-semibold text-gray-500 bg-white">
										Reason
									</th>
									<th className="py-3 sm:text-sm text-xs px-3 text-left font-semibold text-gray-500 bg-white">
										Sample Book
									</th>
									<th className="py-3 sm:text-sm text-xs px-2 sm:px-6 text-left font-semibold text-gray-500 bg-white">
										Action
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
								{data && data.length > 0 ? (
									data.map((order, index) => (
										<tr className="border-b border-gray-200" key={index}>
											<td className="py-4 sm:text-sm text-xs px-5 sm:px-6 text-gray-700 font-normal">
												#{order.Id}
											</td>
											<td className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-gray-700 font-normal">
												{order.Name}
											</td>
											<td className="py-3 px-4 sm:text-sm text-xs sm:px-6 text-gray-700 font-normal">
												{moment(order.CreatedDateTime).format("MM/DD/YYYY")}
											</td>
											<td className="py-3 sm:text-sm text-xs px-4 sm:px-6 font-normal">
												<span
													className={`${getStatusStyle(order.ReviewStatus).color} py-1 px-3 rounded-full sm:text-sm text-xs`}
												>
													{getStatusStyle(order.ReviewStatus).text || "-"}
												</span>
											</td>
											<td className="py-2 sm:text-sm text-xs sm:py-3 px-4 text-gray-700 font-normal">
												{order.Reason || "-"}
											</td>
											<th className="py-2 sm:text-sm text-xs sm:py-3 px-12 text-left font-semibold text-gray-500">
												{order.samplePdf || "-"}
											</th>
											<td className="py-3 px-4 sm:text-sm text-xs sm:px-10 text-gray-700 font-normal">
												{order.Reason ? (
													<span className="text-gray-600 cursor-pointer" onClick={() => handleModelOpen(order.Id)}>Edit</span>
												) : (
													<span className="text-gray-600 cursor-pointer" onClick={() => handleModelOpen(order.Id)}>View</span>
												)}
											</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="7" className="text-center py-4 text-gray-500">
											No data available
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</div>
	);
}

export default ActiveOrder;
