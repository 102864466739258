import React from "react";
import { AiOutlineEdit } from "react-icons/ai";

function Draft({ data, getStatusStyle, handleNavigateBook }) {
	console.log(data, "data");

	return (
		<div className="w-full flex items-center justify-center">
			<div className="w-11/12 sm:w-10/12 md:w-9/12 lg:w-11/12 flex align-middle justify-center mt-10">
				<div className="border border-gray-300 rounded-lg shadow-sm w-full">
					<h2 className="text-xl font-semibold text-gray-700 p-2 ml-1">
						Active Orders
					</h2>
					<div className="overflow-y-auto max-h-80 border border-gray-200 rounded-lg">
						<table className="min-w-full border-collapse">
							<thead className="bg-white sticky top-0 z-10 border-b border-gray-300 shadow-sm">
								<tr>
									<th className="py-3 sm:text-sm text-xs px-4 text-left font-semibold text-gray-500 w-[20%] border-gray-300">
										Book ID
									</th>
									<th className="py-3 sm:text-sm text-xs px-4 text-left font-semibold text-gray-500 w-[20%] border-gray-300">
										Status
									</th>
									<th className="py-3 sm:text-sm text-xs px-2 sm:px-8 font-semibold text-gray-500 w-auto text-right">
										Action
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200 bg-white">
								{data && data.length > 0 ? (
									data.map((order, index) => (
										<tr className="border-b border-gray-200" key={index}>
											<td className="py-4 sm:text-sm text-xs px-5 sm:px-5 text-gray-700 font-normal w-[20%] border-gray-300">
												#{order.Id}
											</td>
											<td className="py-3 sm:text-sm text-xs px-4 sm:px-2 font-normal w-[20%] border-gray-300">
												<span
													className={`${getStatusStyle(order.Status).color} py-1 px-3 rounded-full sm:text-sm text-xs`}
												>
													{getStatusStyle(order.Status).text || "-"}
												</span>
											</td>
											<td onClick={() => handleNavigateBook(order.Id)} className="py-3 px-4 sm:text-sm text-xs sm:px-10 cursor-pointer text-gray-700 font-normal w-auto text-right">
												Edit
											</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="7" className="text-center py-4 text-gray-500">
											No data available
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Draft;
