import React, { useCallback, useContext, useEffect, useState } from "react";
import { FiPlus, FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ApproveOrdersModal from "../../ApproveOrdersModal/ApproveOrdersModal";
import {
  getUserBookDetails,
  getviewDetails,
  getviewUserDetails,
} from "../../../api/userProfile";
import Footer from "../../../components/nav-bar/Footer";
import * as Status from "../../../Entities/RoleStatus";
import * as Order_Status from "../../../Entities/Order-Status";
import { FormContext } from "../../../components/FormContext";
import ActiveOrder from "./ActiveOrder";
import Draft from "./Draft";
import { GoPlus } from "react-icons/go";
import EmptyCard from '../../../assets/images/EmptyCard.png'
import ReactGA from "react-ga";
import { useToast } from "../../../ToastContext";
import CircularProgress from "@mui/material/CircularProgress";

function UserDashboard() {
  const toast = useToast();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, setData, formData } = useContext(FormContext);
  const userId = Number(localStorage.getItem("userId"));
  const [userViewDetails, setVieUserDetails] = useState(null);
  const [activeDraft, setActiveDraft] = useState(""); // Track "Active" or "Draft"
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const fetchViewBook = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getviewDetails(userId);
      if (response?.data && response.status === 200) {
        console.log(response?.data, "response?.data");

        const activeExists = response.data.IsActive;
        const draftExists = response.data.IsDraft;

        if (activeExists && !draftExists) {
          setActiveDraft("Active");
        } else if (!activeExists && draftExists) {
          setActiveDraft("Draft");
        } else if (activeExists && draftExists) {
          setActiveDraft("Active");
        } else {
          setActiveDraft(""); // Show empty state when both are false
        }
      } else {
        console.error("No data found in response.");
      }
    } catch (error) {
      toast.error("Error fetching user details.");
      console.error(error);
    }
    setIsLoading(false);
  }, [userId]);


  const fetchUserDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const isDraftParam = activeDraft === "Draft";
      const response = await getUserBookDetails(userId, isDraftParam);

      if (response?.data && response.status === 200) {
        setData(response.data);
      } else {
        console.error("No data found in response.");
      }
    } catch (error) {
      toast.error("Error fetching user details.");
      console.error(error);
    }
    setIsLoading(false);
  }, [userId, activeDraft, setData, toast]);

  useEffect(() => {
    if (userId) {
      fetchViewBook();
    }
  }, [userId, fetchViewBook]);

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId, activeDraft, fetchUserDetails]);

  const handleClick = () => {
    ReactGA.event({
      category: "Form",
      action: "Navigate to form page",
    });
    navigate("/form");
  };

  const handleModelOpen = async (user) => {
    try {
      const response = await getviewUserDetails(user);
      if (response && response.data) {
        setIsModalOpen(true)
        setVieUserDetails(response.data);
      } else {
        console.error("No data found in response.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleNavigateBook = async (user) => {
    try {
      const response = await getviewUserDetails(user);
      if (response && response.data) {
        localStorage.setItem("userData", JSON.stringify(response.data));
        navigate('/form', { state: { userData: response.data } });
      } else {
        console.error("No data found in response.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleApprove = () => {
    console.log("All selected orders have been approved!");
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case Status.WAITING_APPROVAL:
        return {
          text: Order_Status.WAITING_APPROVAL,
          color: "bg-orange-100 text-orange-600",
        };
      case Status.ACTIVE:
        return {
          text: Order_Status.ACTIVE,
          color: "bg-green-100 text-green-600",
        };
      case Status.INACTIVE:
        return {
          text: Order_Status.INACTIVE,
          color: "bg-gray-100 text-gray-600",
        };
      case Status.REJECTED:
        return {
          text: Order_Status.REJECTED,
          color: "bg-red-100 text-red-600",
        };
      case Status.REVIEWE:
        return {
          text: Order_Status.REVIEWE,
          color: "bg-blue-100 text-blue-600",
        };
      case Status.PRINTING:
        return {
          text: Order_Status.PRINTING,
          color: "bg-green-100 text-green-600",
        };
      case Status.SHIPPING:
        return {
          text: Order_Status.SHIPPING,
          color: "bg-green-100 text-green-600",
        };
      case Status.APPROVED:
        return {
          text: Order_Status.APPROVED,
          color: "bg-green-100 text-green-600",
        };
      case Status.DRAFT:
        return {
          text: Order_Status.DRAFT,
          color: "bg-red-100 text-red-600",
        };
      default:
        return {
          text: "-",
        };
    }
  };

  return (
    <div className="w-full h-screen flex items-center flex-col bg-white">
      {isLoading ? (
        <div className="w-full h-screen flex items-center flex-col bg-white">
          <div
            style={{
              width: "100%",
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={24} color="success" />
          </div>
        </div>
      ) : activeDraft === "" || !data || data.length === 0 ? ( // <-- Check explicitly if activeDraft is empty
        <div className="flex flex-col items-center justify-center min-h-screen bg-white">
          <img src={EmptyCard} alt="Empty Dashboard" className="w-72 h-72" />

          <h2 className="mt-6 text-xl font-semibold text-gray-900 font-poppins">
            Your Dashboard Is Empty.
          </h2>
          <p className="text-gray-500 text-sm text-center max-w-sm font-poppins">
            Book your order now and see the status on the dashboard.
          </p>

          <button
            onClick={handleClick}
            className="font-SegoeUI flex items-center gap-2 px-6 py-2 mt-4 text-white bg-teal-600 rounded-md hover:bg-teal-700 transition duration-300"
          >
            <GoPlus size={18} />
            Create Story Book
          </button>
        </div>
      ) : (
        <>
          <div className="text-left w-full  px-16 sm:px-8 md:px-16 mt-10">
            <h1 className="text-2xl font-normal text-customGreyBase font-Merriweather">
              Dashboard
            </h1>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-between w-full px-4 sm:px-8 md:px-16 mt-3 border-b border-[#E1E6E6]">
            <div className="flex flex-row lg:flex-row items-center space-x-4 lg:space-y-0 lg:space-x-4 mt-5">
              <button
                onClick={() => setActiveDraft("Active")}
                className={`pb-2 border-b-2 ${activeDraft === "Active"
                  ? "border-customGreen text-customGreen font-semibold"
                  : "border-transparent text-gray-500"
                  }`}
              >
                ACTIVE
              </button>
              <button
                onClick={() => setActiveDraft("Draft")}
                className={`pb-2 border-b-2 ${activeDraft === "Draft"
                  ? "border-customGreen text-customGreen font-semibold"
                  : "border-transparent text-gray-500"
                  }`}
              >
                DRAFT
              </button>
            </div>
            <button
              onClick={handleClick}
              className="mt-4 lg:mt-0 mb-2 text-sm bg-customGreen hover:bg-[#006666] p-3 md:p-4 w-auto flex items-center justify-center gap-2 rounded-md text-white font-normal font-SegoeUl transition-colors duration-200"
            >
              <FiPlus size={16} color="#FFFFFF" className="mt-1" />
              Create Story Book
            </button>
          </div>

          <div className="w-full flex items-center justify-center">
            {activeDraft === "Active" && (
              <ActiveOrder
                data={data}
                getStatusStyle={getStatusStyle}
                handleModelOpen={handleModelOpen}
              />
            )}
            {activeDraft === "Draft" && (
              <Draft
                data={data}
                getStatusStyle={getStatusStyle}
                handleNavigateBook={handleNavigateBook}
              />
            )}
          </div>
          <ApproveOrdersModal
            fetchUserDetails={fetchUserDetails}
            userViewDetails={userViewDetails}
            isOpen={isModalOpen}
            closeModal={closeModal}
            handleApprove={handleApprove}
          />
        </>
      )}

      <div className="mt-44 w-full">
        <Footer />
      </div>
    </div>
  );
}

export default UserDashboard;
