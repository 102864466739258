import React, { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../../ToastContext";
import { GoogleLogin } from "@react-oauth/google";
import { CircularProgress } from "@mui/material";
import { AuthSignUpAPI, AuthSignUpCognito, registerAPI } from "../../../api/RegisterAPI";
import LoginFirstImg from "../../../assets/images/loginBackgroundFirstImg.png";
import LoginSecondImg from "../../../assets/images/loginBackgroundSecondmg.png";
import SignUpImg from "../../../assets/images/signUpImg.png";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { jwtDecode } from "jwt-decode";

function SignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsAcceptedModelOpen, setTermsAcceptedModelOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [step, setStep] = useState(1);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
      setStep(2);
    }
  }, [location.state?.email]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value;
    setPassword(enteredPassword);

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (!passwordRegex.test(enteredPassword)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const enteredConfirmPassword = e.target.value;
    setConfirmPassword(enteredConfirmPassword);
    setConfirmPasswordError(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setConfirmPasswordError(false);

    try {
      if (!firstName || !lastName || !email || !password || !confirmPassword) {
        toast.error("All fields are required");
        setLoading(false);
        return;
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError(true);
        setLoading(false);
        return;
      }

      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (!passwordRegex.test(password)) {
        setPasswordError(true);
        setLoading(false);
        return;
      }

      const userData = {
        firstName: firstName,
        lastName: lastName,
        emailId: email,
        password: password,
      };

      const response = await registerAPI(userData);
      if (response && response.data && response.status === 200) {
        toast.success("Account created successfully!");
        setStep(2);
        setLoading(false);
      } else {
        toast.error("Signup failed. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error && error.response && error.response.status === 409) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error. Please try again.");
        console.error("SignUp error:", error);
      }
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const payload = {
        emailid: email,
        otp: otp,
      }
      const response = await AuthSignUpCognito(payload);
      if (response && response.data) {
        toast.success("OTP verified successfully!");
        setEmail("");
        setStep(3);
      }
    } catch (error) {
      console.error("OTP Verification Error:", error);
      toast.error(error.message || "Invalid OTP. Try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async (response) => {
    try {
      if (!response || !response.credential) {
        toast.error("Google login failed. No credential received.");
        return;
      }

      const credential = response.credential;
      const decodedToken = jwtDecode(credential);
      const email = decodedToken?.email;
      const firstName = decodedToken?.given_name;
      const lastName = decodedToken?.family_name;

      if (!email) {
        toast.error("Failed to retrieve email from Google login.");
        return;
      }
      const payload = {
        emailId: email,
        firstName: firstName,
        lastName: lastName || "",
      };
      const apiResponse = await AuthSignUpAPI(payload);
      if (apiResponse?.data) {
        const userId = apiResponse?.data?.Id;
        const RoleId = 3;
        localStorage.setItem("userId", userId);
        localStorage.setItem("accessToken", credential);
        localStorage.setItem("RoleId", RoleId);
        toast.success("Logged in with Google successfully!");
        const redirectUrl = localStorage.getItem('redirectAfterLogin') || "/home";
        localStorage.removeItem('redirectAfterLogin');
        window.location.href = redirectUrl;
      } else {
        toast.error("Google login API request failed.");
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Google login failed. Please try again.");
    }
  };

  const handleBackToLogin = () => {
    navigate("/login");
  };

  const handleAccept = () => {
    setTermsAccepted(true);
    setTermsAcceptedModelOpen(false);
  };

  const handleCloseModel = () => {
    setTermsAcceptedModelOpen(false);
  };

  const maskEmail = (email) => {
    if (!email) return "";
    const [localPart, domain] = email.split("@");
    if (!domain) return email;

    const domainParts = domain.split(".");
    const maskedLocal = localPart.slice(0, 2) + "****";
    const maskedDomain = domainParts.length > 1 ? `****.${domainParts.pop()}` : domain;

    return `${maskedLocal}@${maskedDomain}`;
  };

  const handleOpenModel = () => {
    setTermsAcceptedModelOpen(true)
  };


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 lg:px-4">
      <div className=" w-full max-w-6xl bg-slate-500 flex flex-col lg:flex-row shadow-lg rounded-lg overflow-hidden min-h-[90vh]">
        <div className="lg:w-1/2 flex items-center flex-col justify-between bg-customGreen">
          <div className="h-[30%] w-full flex justify-start lg:justify-start">
            <img
              src={LoginFirstImg}
              alt="LoginFirstImg"
              className="w-[130px] h-[130px] lg:w-[200.07px] lg:h-[200.02px]"
            />
          </div>
          <div className="text-center w-full h-[40%] mt-2 flex items-start flex-col justify-center px-4 lg:ml-10 lg:pl-16">
            <div className="relative pb-6">
              <h3 className="text-2xl lg:text-4xl text-left font-semibold text-white">
                Welcome to
                <br /> Journey Book
              </h3>
              <div className="w-1/2 lg:w-2/3 border-b-4 border-white absolute bottom-0 left-0"></div>
            </div>
          </div>
          <div className="h-[30%] w-full flex justify-end lg:justify-end items-end">
            <img
              src={LoginSecondImg}
              alt="LoginSecondImg"
              className="w-[130px] h-[130px] lg:w-[200px] lg:h-[200px]"
            />
          </div>
        </div>
        <div className="lg:w-1/2 p-8 bg-white overflow-auto h-fullflex-col items-center justify-center flex">
          {step === 1 && (
            <div className="w-full">
              <div className="text-center mb-6">
                <h1 className="font-poppins font-semibold text-base md:text-xl">
                  Create your account <br /> Create your story
                </h1>
              </div>
              <div className="w-full">
                <GoogleLogin
                  className="flex items-center justify-center bg-[#F8FAFC] rounded-md p-2 mb-6 cursor-pointer hover:bg-[#CBD5E1] w-full"
                  onSuccess={handleGoogleLogin}
                  onError={() => toast.error("Google login failed")}
                  useOneTap
                />
              </div>
              <div className="flex items-center mt-4">
                <hr className="flex-grow border-gray-300" />
                <span className="px-2 text-gray-500 text-xs md:text-sm font-roboto">
                  or With
                </span>
                <hr className="flex-grow border-gray-300" />
              </div>
              <form className="space-y-4" onSubmit={handleSignUp}>
                <div className="flex flex-col">
                  <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="flex flex-col relative">
                  <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`border ${passwordError ? 'border-red-500' : 'border-gray-300'} rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]`}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {passwordError && password && (
                    <span className="text-red-500 text-xs mt-1 font-poppins">
                      Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a number.
                    </span>
                  )}
                  <span
                    className="absolute right-3 top-9 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>

                <div className="flex flex-col relative">
                  <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                    Confirm Password
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className={`border ${confirmPasswordError ? 'border-red-500' : 'border-gray-300'} rounded-md p-2 mt-1 focus:ring-2 focus:ring-[#CBD5E1] focus:outline-none bg-[#F8FAFC]`}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {confirmPasswordError && (
                    <span className="text-red-500 text-xs mt-1">
                      Passwords do not match.
                    </span>
                  )}
                  <span
                    className="absolute right-3 top-9 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>

                <div className="flex items-center text-xs md:text-sm w-full">
                  <div className="flex items-center w-full flex-row">
                    <span className="text-gray-600">
                      I agree with the{" "}
                      <span onClick={handleOpenModel} className="text-teal-600 hover:underline cursor-pointer">
                        Terms & Conditions
                      </span>{" "}
                      of Clarity
                    </span>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={!termsAccepted || loading}
                  className={`w-full cursor-pointer font-semibold font-Poppins text-xs md:text-sm ${termsAccepted ? "bg-teal-600" : "bg-gray-400"
                    } text-white py-2 rounded-md hover:${termsAccepted ? "bg-teal-700" : "bg-gray-400"
                    } transition duration-300`}
                >
                  {loading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    "Sign Up"
                  )}
                </button>
                <p className="text-xs text-center md:text-sm text-gray-600 mt-4">
                  Already have an account?{" "}
                  <a href="/login" className="text-teal-600 hover:underline">
                    Log In
                  </a>
                </p>
              </form>
            </div>
          )}

          {step === 2 && (
            <div className="w-full">
              <h1 className="text-2xl font-semibold text-center mb-4">Confirm Your Email Verification</h1>
              <p className="text-sm text-gray-500 text-center pb-5">
                We’ve sent a verification code to your registered email to confirm that <span className="font-medium text-black">{maskEmail(email)}</span>. Please enter the code below to complete the verification process
              </p>
              <div className="flex space-x-2 justify-center mb-1">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="w-10 h-10 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#CBD5E1] bg-[#F8FAFC]"
                    value={otp[index] || ""}
                    onChange={(e) => {
                      const newOtp = otp.split("");
                      newOtp[index] = e.target.value;
                      setOtp(newOtp.join(""));
                      if (e.target.value !== "" && index < 5) {
                        document
                          .getElementById(`otp-input-${index + 1}`)
                          .focus();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && !otp[index] && index > 0) {
                        const newOtp = otp.split("");
                        newOtp[index - 1] = "";
                        setOtp(newOtp.join(""));
                        document
                          .getElementById(`otp-input-${index - 1}`)
                          .focus();
                      }
                    }}
                    id={`otp-input-${index}`}
                  />
                ))}
              </div>
              <div className="flex items-center justify-center w-full">
                <button
                  disabled={loading}
                  type="button"
                  className="w-1/2 cursor-pointer font-semibold font-Poppins text-xs md:text-sm bg-teal-600 text-white py-2 rounded-md hover:bg-teal-700 transition duration-300 mt-4"
                  onClick={handleVerifyOtp}
                >
                  {loading ? <CircularProgress size={18} color="inherit" /> : "Verify OTP"}
                </button>
              </div>

            </div>
          )}

          {step === 3 && (
            <div className="flex flex-col items-center justify-center text-center w-full">
              <div className="pb-6 flex justify-center">
                <img
                  src={SignUpImg}
                  alt="ThankYou"
                  className="w-[299px] h-[299px] md:w-[250px] md:h-[250px] sm:w-[200px] sm:h-[200px]"
                />
              </div>
              <div>
                <h2 className="text-[#090914] font-poppins font-semibold text-lg md:text-3xl text-center">
                  You have successfully
                  <br /> create an account !
                </h2>
              </div>
              <div className="pb-9 w-full">
                <button
                  onClick={handleBackToLogin}
                  className="mt-6 w-[100%] lg:w-[70%] md:w-[50%] cursor-pointer font-semibold font-Poppins text-xs md:text-sm bg-teal-600 text-white py-2 px-6 rounded-md hover:bg-teal-700 transition duration-300"
                >
                  Back to login
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {termsAcceptedModelOpen && (
        <TermsAndConditionsModal
          isOpen={termsAcceptedModelOpen}
          onClose={handleCloseModel}
          onAccept={handleAccept}
          setTermsAccepted={setTermsAccepted}
          termsAccepted={termsAccepted}
        />
      )}
    </div>
  );
}

export default SignUp;
