export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const WAITING_APPROVAL = "Waiting for Approval";
export const REJECTED = "Rejected";
export const REVIEWE = "Reviewed";
export const PRINTING = "Printing";
export const SHIPPING = "Shipping";
export const APPROVED = "Approved";
export const DRAFT = "Draft";

