import React from "react";
import JourneyBooks from "../../assets/images/Journey_Books.png-removebg-preview.png";
import ItemFacebook from "../../assets/images/ItemFackbookLink.png";
import itemInsta from "../../assets/images/itemInsta.png";
import ItemYoutube from "../../assets/images/ItemYoutube.png";

import { Link } from "react-router-dom";

function Footer() {
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

  const bookImages = [
    { img: ItemFacebook, url: "https://www.facebook.com/61563588000593/" },
    { img: itemInsta, url: "https://www.instagram.com/journey_books/" },
    { img: ItemYoutube, url: "https://www.youtube.com/@JourneyBooks" },
  ];

  const handleLink = (url) => {
    window.location.href = url;
  };
  return (
    <footer className="bg-[#000606] text-white py-5">
      <div className="container mx-auto px-4 md:px-5 md:py-2">
        <div className="flex flex-wrap lg:flex-nowrap justify-between items-start space-y-8 lg:space-y-0">
          <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start">
            <img src={JourneyBooks} alt="JourneyBooks" className="w-32 mb-4" />
            {/* <p className="text-center lg:text-left mb-4 font-roboto font-normal text-base">
              Nemo enim ipsam voluptatem quia
              <br />
              voluptas sit aspernatur aut odit aut fugit,
              <br />
              sed quia magni this dolores eos qui ratione.
            </p> */}
            <div className="flex space-x-4">
              {bookImages.map((item, index) => (
                <button
                  key={index}
                  onClick={() => handleLink(item.url)}
                  className="hover:opacity-80 transition cursor-pointer"
                >
                  <img
                    src={item.img}
                    alt={`Link to ${item.url}`}
                    className="w-10 h-10"
                  />
                </button>
              ))}
            </div>
          </div>
          <div className="w-full lg:w-1/4 text-center lg:text-left">
            <h3 className="text-lg font-poppins font-semibold mb-4">
              Quick Links
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/home"
                  className="hover:text-teal-400 transition font-roboto font-normal text-base"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className="hover:text-teal-400 transition font-roboto font-normal text-base"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/works"
                  className="hover:text-teal-400 transition font-roboto font-normal text-base"
                >
                  How It Works
                </Link>
              </li>
              <li>
                <Link
                  to="/testimonials"
                  className="hover:text-teal-400 transition font-roboto font-normal text-base"
                >
                  Testimonial
                </Link>
              </li>
              <li>
                <Link
                  to="/contactUs"
                  className="hover:text-teal-400 transition font-roboto font-normal text-base"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/3 text-center lg:text-left">
            <h3 className="text-lg font-poppins font-semibold mb-4">
              Contact Us
            </h3>
            <ul className="space-y-2">
              <li>
                <p className="font-roboto font-normal text-base">
                  T.G. Hobbs Enterprises Inc. <br />
                  3209 S. Galena Ct., Denver, CO 80231
                </p>
              </li>
              <li>
                <p className="font-roboto font-normal">
                  Phone: (+1) 720-295-5812
                </p>
              </li>
              <li>
                <a
                  href="mailto:info@journeybookstories.com"
                  className="hover:text-teal-400 transition font-roboto font-normal text-base"
                >
                  Email: info@journeybookstories.com
                </a>
              </li>
              <li>
                <a
                  href={websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-teal-400 transition font-roboto font-normal text-base"
                >
                  Website: www.journeybook.com
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start space-y-4 mt-8 lg:mt-0">
            {/* <h3 className="text-lg font-poppins font-semibold text-center lg:text-left">
              News Letter
            </h3>
            <p className="text-center font-roboto lg:text-left max-w-md font-normal text-base">
              Our approach to itis unique around know
              <br />
              work an we know Get hands on the you like
            </p> */}
            <form className="bg-white py-2 px-2 mt-10 md:w-auto rounded-md flex flex-col md:flex-row lg:flex-row items-center md:space-x-2 space-y-4 md:space-y-0 w-auto">
              <input
                type="text"
                placeholder="Your Email"
                className="p-3 rounded-lg text-black font-roboto shadow-sm w-full md:w-auto lg:w-auto outline-none focus:ring-2 focus:ring-teal-400"
              />
              <button
                type="button"
                className="bg-teal-500 text-sm text-white px-6 py-3 font-roboto rounded-lg hover:bg-teal-600 transition w-full md:w-auto font-semibold cursor-pointer"
              >
                SUBSCRIBE
              </button>
            </form>
          </div>
        </div>
        <div className="mt-20 border-t border-gray-700 pt-4 flex flex-wrap justify-between items-center text-sm">
          <p className="w-full lg:w-auto text-center lg:text-left font-roboto font-normal text-base">
            Copyright © Journey Book all rights reserved.
          </p>
          <div className="w-full lg:w-auto flex justify-center lg:justify-end space-x-4">
            <Link
              to="/privacypolicy"
              className="hover:text-teal-400 transition font-roboto font-normal text-base"
            >
              Privacy Policy
            </Link>
            <Link
              to="/"
              className="hover:text-teal-400 transition font-roboto font-normal text-base"
            >
              Language
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
