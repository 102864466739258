// src/App.js
import React, { useEffect } from "react";
import { Routing } from "./routes/Routing";
import ReactGA from "react-ga";
import { Toaster } from "react-hot-toast";
import "./App.css";
import { ToastProvider } from "./ToastContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FormProvider } from "./components/FormContext";
import { NavigationProvider } from "./components/NavigationContext";
import { UserFormProvider } from "./components/UserFormContext";

const App = () => {

  useEffect(() => {
    ReactGA.initialize("G-D3YYY1TKVY");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const clientId = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID
  return (
    <UserFormProvider>
      <NavigationProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <ToastProvider>
            <FormProvider>
              <div>
                <Routing />
                <Toaster />
              </div>
            </FormProvider>
          </ToastProvider>
        </GoogleOAuthProvider>
      </NavigationProvider>
    </UserFormProvider >
  );
};

export default App;
