import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../ToastContext";
import { GoogleLogin } from "@react-oauth/google";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { CircularProgress } from "@mui/material";
import { loginAPI } from "../../api/LoginAPI";
import LoginFirstImg from "../../assets/images/loginBackgroundFirstImg.png";
import LoginSecondImg from "../../assets/images/loginBackgroundSecondmg.png";
import { jwtDecode } from "jwt-decode";
import { AuthSignUpAPI } from "../../api/RegisterAPI";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const toggleCheckbox = () => {
    const updatedValue = !rememberMe;
    setRememberMe(updatedValue);
    localStorage.setItem("IsRememberMe", JSON.stringify(updatedValue));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!email || !password) {
        toast.error("Invalid Credentials", { duration: 7000 });
        setLoading(false);
        return;
      }

      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(email)) {
        toast.error("Invalid Credentials", { duration: 7000 });
        setLoading(false);
        return;
      }

      if (password.length < 8) {
        toast.error("Invalid Credentials", { duration: 7000 });
        setLoading(false);
        return;
      }

      const payload = {
        emailId: email,
        password: password,
      };

      const response = await loginAPI(payload);

      if (response?.data && response?.status === 200) {
        toast.success("Logged in successfully!");
        const token = response?.data?.accessToken;
        const userId = response?.data?.User?.Id;
        const RoleId = response?.data?.User?.RoleId;
        localStorage.setItem("accessToken", token);
        localStorage.setItem("userId", userId);
        localStorage.setItem("RoleId", RoleId);
        const redirectUrl = localStorage.getItem('redirectAfterLogin') || "/home";
        localStorage.removeItem('redirectAfterLogin');
        window.location.href = redirectUrl;
        setEmail("");
        setPassword("");
        setRememberMe(false);
        setLoading(false);
      } else {
        toast.error("Invalid Credentials", { duration: 7000 });
        setLoading(false);
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Invalid Credential", { duration: 7000 });
    } finally {
      setLoading(false);
    }
  };



  const handleGoogleLogin = async (response) => {
    try {
      if (!response || !response.credential) {
        toast.error("Google login failed. No credential received.");
        return;
      }

      const credential = response.credential;
      const decodedToken = jwtDecode(credential);
      const email = decodedToken?.email;
      const firstName = decodedToken?.given_name;
      const lastName = decodedToken?.family_name;

      if (!email) {
        toast.error("Failed to retrieve email from Google login.");
        return;
      }
      const payload = {
        emailId: email,
        firstName: firstName,
        lastName: lastName || " ",
      };

      const apiResponse = await AuthSignUpAPI(payload);
      if (apiResponse?.data) {
        const userId = apiResponse?.data?.Id;
        const RoleId = 3;
        localStorage.setItem("userId", userId);
        localStorage.setItem("accessToken", credential);
        localStorage.setItem("RoleId", RoleId);
        toast.success("Logged in with Google successfully!");
        const redirectUrl = localStorage.getItem('redirectAfterLogin') || "/home";
        localStorage.removeItem('redirectAfterLogin');
        window.location.href = redirectUrl;
      } else {
        toast.error("Google login API request failed.");
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Google login failed. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 lg:px-4">
      <div className="bg-white w-full max-w-6xl flex flex-col lg:flex-row shadow-lg rounded-lg overflow-hidden min-h-[90vh]">
        <div className="lg:w-1/2 flex items-center flex-col justify-between bg-customGreen">
          <div className="h-[30%] w-full flex justify-start lg:justify-start">
            <img
              src={LoginFirstImg}
              alt="LoginFirstImg"
              className="w-[130px] h-[130px] lg:w-[200.07px] lg:h-[200.02px]"
            />
          </div>
          <div className="text-center w-full h-[40%] mt-2 flex items-start flex-col justify-center px-4 lg:ml-10 lg:pl-16">
            <div className="relative pb-6">
              <h3 className="text-2xl lg:text-4xl text-left font-semibold text-white">
                Welcome Back To <br /> Journey Book
              </h3>
              <div className="w-1/2 lg:w-2/3 border-b-4 border-white absolute bottom-0 left-0"></div>
            </div>
            {/* <p className="text-customLiteWhite text-left mt-4 text-sm lg:text-base">
              Sign in to continue to your account.
            </p> */}
          </div>
          <div className="h-[30%] w-full flex justify-end lg:justify-end items-end">
            <img
              src={LoginSecondImg}
              alt="LoginSecondImg"
              className="w-[130px] h-[130px] lg:w-[200px] lg:h-[200px]"
            />
          </div>
        </div>
        <div className="lg:w-1/2 p-8 md:p-24 bg-white">
          <div className="text-center mb-6">
            <h1 className="font-poppins font-semibold text-base md:text-xl">
              Sign in to continue <br />
              to your account
            </h1>
            {/* <p className="text-customLiteGrey font-poppins text-xs md:text-sm">
              Clarity gives you the blocks and components you need to create a
              truly professional website.
            </p> */}
          </div>
          <div className="w-full">
            <GoogleLogin
              className="flex items-center justify-center bg-[#F8FAFC] rounded-md p-2 mb-6 cursor-pointer hover:bg-gray-200 w-full"
              onSuccess={handleGoogleLogin}
              onError={() => toast.error("Google login failed")}
              useOneTap
            />
          </div>
          <div className="flex items-center mt-4">
            <hr className="flex-grow border-gray-300" />
            <span className="px-2 text-gray-500 text-xs md:text-sm font-roboto">
              or With
            </span>
            <hr className="flex-grow border-gray-300" />
          </div>
          <form className="space-y-4" onSubmit={handleLogin}>
            <div className="flex flex-col">
              <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                Email address
              </label>
              <input
                type="email"
                className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-blue-200 focus:outline-none bg-[#F8FAFC]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-col relative">
              <label className="text-customLabel font-medium text-xs md:text-sm font-Poppins">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-blue-200 focus:outline-none bg-[#F8FAFC]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="absolute right-3 top-9 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </span>
            </div>

            <div className="flex justify-between items-center text-xs md:text-sm">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2 w-4 h-4"
                  checked={rememberMe}
                  onChange={toggleCheckbox}
                />
                <span className="text-gray-600 font-poppins font-normal">
                  Remember me
                </span>
              </div>
              <a
                href="/forgot-password"
                className="text-teal-600 hover:underline font-poppins font-normal"
              >
                Forgot password?
              </a>
            </div>
            <div>
              <button
                disabled={loading}
                type="submit"
                className="w-full cursor-pointer font-semibold font-Poppins text-xs md:text-sm bg-teal-600 text-white py-2 rounded-md hover:bg-teal-700 transition duration-300"
              >
                {loading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  "Log In"
                )}
              </button>
            </div>
            <p className="text-left text-gray-600 font-Poppins font-medium text-xs md:text-sm">
              Don’t have an account?{" "}
              <a
                href="/signup"
                className="text-left text-teal-600 hover:underline font-medium"
              >
                Sign Up
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
