import React, { useEffect, useRef } from "react";
import "../../style/aboutUs.css";
import AboutFirstImg from "../../assets/images/about-us.png";
import Footer from "../../components/nav-bar/Footer";

function AboutUs() {
    const dashboardRef = useRef(null);
  
  useEffect(() => {
    if (dashboardRef.current) {
      dashboardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="aboutPage-page" ref={dashboardRef}>
      <div className="aboutPage-container-page">
        <div className="aboutPage-first-pages">
          <div className="aboutPage-view-first-img">
            <img
              src={AboutFirstImg}
              className="aboutPage-img-first"
              alt="aboutUsImg"
            />
          </div>
        </div>
        <div className="aboutPage-second-pages">
          <div>
            <span className="aboutPage-second-heading-text">About Us</span>
          </div>
          <div className="para-aboutUsPage-container">
            <div className="paraFirst-aboutUsPage-container">
              <p className="aboutPage-content">
                Welcome to Journey Book, where every page tells a story and
                every story is a journey. Our mission is to create personalized
                books that celebrate the unique journey of each child, fostering
                a love for reading and a deeper understanding of the world
                around them. At Journey Book, we believe that every child’s
                journey is special. That’s why we offer custom-tailored books
                that not only captivate young readers but also serve as a bridge
                of empathy, connecting them to diverse experiences and
                perspectives. Our books are designed to be cherished keepsakes,
                capturing moments of joy, learning, and growth. Thank you for
                choosing Journey Book to be a part of your child’s journey.
                Together, we can create a legacy of love, learning, and
                understanding that will last a lifetime.
              </p>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
