import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import sideImg from "../../assets/images/sidebar-left.png";
import StoryInformationForm from "./StoryInformationForm";
import ApproveSection from "./ApproveSection";
import { useNavigate, useParams } from "react-router-dom";
import { getUserBookDetails, getviewUserDetails } from "../../api/userProfile";
import MailHeader from "./MailHeader";
import { useNavigation } from "../../components/NavigationContext";

ReactModal.setAppElement("#root");

function MailURL() {
	const navigate = useNavigate();
	const [reason, setReason] = useState("");
	const [uploadedImages, setUploadedImages] = useState([]);
	const roleId = localStorage.getItem("RoleId");
	const parsedRoleId = parseInt(roleId, 10);
	const [status, setStatus] = useState("");
	const [userViewDetails, setViewUserDetails] = useState(null);
	const [datas, setDatas] = useState([])
	const userId = Number(localStorage.getItem("userId"));
	const param = useParams()

	const fetchUserDetails = useCallback(async () => {
		try {
			const response = await getUserBookDetails(userId);
			if (response && response.data) {
				setDatas(response.data);
			} else {
				console.error("No data found in response.");
			}
		} catch (error) {
			console.error("Error fetching user details:", error);
		}
	}, [userId]);

	useEffect(() => {
		if (userId) {
			fetchUserDetails();
		}
	}, [userId, fetchUserDetails]);

	const validateAndRedirect = useCallback(() => {
		const validPaths = ["/home", "/about-us", "/works", "/testimonials", "/contactUs"];
		const bookId = param.bookId;
		console.log(bookId, "bookId");
		if (!bookId || isNaN(Number(bookId))) {
			const currentPath = window.location.pathname;
			const redirectPath = validPaths.find(path => currentPath.includes(path)) || "/home";
			navigate(redirectPath, { replace: true });
		}
	}, [param.bookId, navigate]);

	useEffect(() => {
		validateAndRedirect();
	}, [validateAndRedirect]);


	useEffect(() => {
		const fetchUserDetails = async () => {
			if (!datas || datas.length === 0) return;
			try {
				const response = await getviewUserDetails(param.bookId);
				if (response && response.data) {
					setViewUserDetails(response.data);
				} else {
					console.error("No data found in response.");
				}
			} catch (error) {
				console.error("Error fetching user details:", error);
			}
		};
		if (datas?.length > 0) {
			fetchUserDetails();
		}
	}, [datas, navigate]);


	return (
		<div className="w-full">
			<MailHeader
				setStatus={setStatus}
				userViewDetails={userViewDetails}
				sideImg={sideImg}
			/>
			<div className="p-3 mt-20">
				<div className="mb-4">
					<div className="flex items-center gap-3">
						<h2 className="font-semibold text-base mb-2 whitespace-nowrap font-SegoeUI text-[#696A6C]">
							Story Information
						</h2>
						<hr className="flex-grow border-t border-gray-300" />
					</div>
					<StoryInformationForm
						roleId={roleId}
						uploadedImages={uploadedImages}
						userViewDetails={userViewDetails}
						setUploadedImages={setUploadedImages}
					/>
				</div>
				<ApproveSection
					userViewDetails={userViewDetails}
					status={status}
					roleId={parsedRoleId}
					setStatus={setStatus}
					reason={reason}
					setReason={setReason}
				/>
			</div>
		</div>
	);
}

export default MailURL;
