import React from "react";
import "../../style/form.css";
import ThankYou from "../../assets/images/thankYou.png";
import { useNavigate } from "react-router-dom";

function SuccessMessage() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/home");
  };
  return (
    <div className="first-form-container">
      <div className="input-container-success">
        <div>
          <img src={ThankYou} width="250px" height="250px" alt="ThankYou" />
        </div>
        <div style={{ marginTop: "20px" }}>
          <p className="thankYou-page-para">
            Congratulation your details Successfully Uploaded.
          </p>
          <span className="font-Inter text-base text-center font-normal text-[#000606]
">Your Journey Book is officially in motion! </span>
        </div>
        <div className="mt-2">
          <span className="font-Inter text-base text-center font-medium text-[#005A5A]">What’s Next?</span>
          <p className="font-Inter text-base text-center font-normal text-[#707373]">
            We’re carefully reviewing your responses to create your child’s personalized Journey Book. You’ll receive an update from us soon, or feel free to check back for progress.
          </p>
        </div>
        <div>
          <span className="text-[#009A9A] text-center font-Inter text-base font-medium">Stay tuned - we can’t wait to bring your book to life!</span>
        </div>
        <div style={{ marginTop: "20px" }}>
          <button onClick={handleClick} className="custom-button-ssuccess-btn">
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessMessage;
