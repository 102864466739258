import React, { useState } from 'react';
import "../../style/home.css";
import BooksOne from '../../assets/images/booksOne.png';
import BooksTwo from '../../assets/images/BooksTwo.png';
import BooksThree from '../../assets/images/BooksThree.png';
import BooksFour from '../../assets/images/BooksFour.png';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

function Book() {
  const bookImages = [BooksOne, BooksTwo, BooksThree, BooksFour];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? bookImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bookImages.length);
  };

  const effectiveIndex = currentImageIndex % bookImages.length;

  return (
    <div className="home-books-container">
      <div className="sub-books-container">
        <div className='books-content-container'>
          <div>
            <p className='heading-books-Page'>Why Choose Us</p>
          </div>
          <div style={{ marginTop: '5px' }}>
            <p className='subHeading-books-Page'>The Journey Books™ Experience</p>
          </div>
          <div className='books-align-content-text-card'>
            <p className='book-para-text'>
              When you choose Journey Book, you're not just receiving a product; you're creating a
              legacy of love and understanding. Our custom-tailored books are designed to
              celebrate your child's unique journey and to serve as a bridge of empathy to others.
            </p>
          </div>
        </div>
        <div className='books-img-view'>
          <div className="image-slider" style={{
            transform: `translateX(-${effectiveIndex * (397 + 10)}px)`
          }}>
            {bookImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Book ${index + 1}`}
                className='book-image'
                style={{ marginLeft: index !== 0 ? '10px' : '0' }}
              />
            ))}
            {bookImages.map((image, index) => (
              <img
                key={index + bookImages.length}
                src={image}
                alt={`Book ${index + 1}`}
                className='book-image'
                style={{ marginLeft: index !== 0 ? '10px' : '0' }}
              />
            ))}
          </div>
          <div className="image-slider-mobile">
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <img
                key={effectiveIndex}
                width={'100%'}
                height={'100%'}
                src={bookImages[effectiveIndex]}
                alt={`Book ${effectiveIndex + 1}`}
                className='book-image'
              />
            </div>
          </div>
          <div className="arrow-icons">
            <FaArrowLeft onClick={handlePrev} className="arrow-icon arrow-left" size={24} />
            <FaArrowRight onClick={handleNext} className="arrow-icon arrow-right" size={24} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Book;
