// import axios from "axios";
import instance from "../axiosConfig";

const url = process.env.REACT_APP_API_URL + "user";
const URL = process.env.REACT_APP_API_URL + "user/profile";
const EndPoint = process.env.REACT_APP_API_URL + "book";

export const UserDetails = async (id) => {
  const response = await instance.get(`${url}/${id}`);
  return response;
};

export const CreateAndUpdateUser = async (payload) => {
  const response = await instance.post(URL, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const getUserBookDetails = async (userId, isDraftParam) => {
  const response = await instance.get(`${EndPoint}/user-books/${userId}/${isDraftParam}`);
  return response;
};

export const getviewUserDetails = async (id) => {
  const response = await instance.get(`${EndPoint}/${id}`);
  return response;
};

export const getviewDetails = async (id) => {
  const response = await instance.get(`${EndPoint}/books-view/${id}`);
  return response;
};
