export const ACTIVE = 1;
export const INACTIVE = 2;
export const WAITING_APPROVAL = 3;
export const REJECTED = 4;
export const REVIEWE = 5;
export const PRINTING = 6;
export const SHIPPING = 7;
export const APPROVED = 8;
export const DRAFT = 9;

